import api from "helpers/api"
import { usePageId } from "hooks/usePageId"
import { useRegion } from "hooks/useRegion"
import { FC, useMemo } from "react"
import toast from "react-hot-toast"
import { useSelector } from "react-redux"
import { getUserCredentials } from "store/selectors/getUserCredentials"
import { GetReferenceNomenclatureResponse } from "types/api"
import { hasPermissionForElementPage } from "../../helpers/permissions"
import NomenclatureDetailPricesTable from "./NomenclatureDetail.Prices.Table"

export const NomenclatureDetailPrices: FC<{
    nomenclature: GetReferenceNomenclatureResponse
}> = ({ nomenclature }) => {
    const credentials = useSelector(getUserCredentials)
    const { activeRegion } = useRegion()
    const { id } = usePageId()

    const nomenclaturePrices = useMemo(() => {
        if (!nomenclature || !activeRegion) return []
        return (
            nomenclature.prices.find((el) => el.region === activeRegion.value)
                ?.prices_region ?? []
        )
    }, [nomenclature, activeRegion])

    const handleRecalculatePrices = async () => {
        await toast.promise(
            api.get(`v1/nomenclature/calculate-all-prices/${id}`, {
                region: activeRegion?.value,
            }),
            {
                loading: "Обрабатываем...",
                success: <b>Пересчет успешно запущен!</b>,
                error: ({ response: { data: serverTextResponse } }) => {
                    const error =
                        serverTextResponse ?? "Произошла неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )
    }

    const getPriceString = (
        price: GetReferenceNomenclatureResponse["prices"][number]["prices_region"][number]
    ) => {
        const basePrice = price.price
        const currency = price.currency?.isoCode ?? "[валюта не указана]"
        const chunks = [basePrice, currency]
        if (!price.priceIncludesVAT) {
            chunks.push(`/ ${price.priceWithVat} с НДС`)
        }
        return chunks.join(" ")
    }

    return (
        <div className="control-in">
            {hasPermissionForElementPage(
                credentials,
                "viewStartRecalculation"
            ) && (
                <h5
                    style={{
                        marginBottom: 15,
                    }}
                    className="d-flex justify-content-between"
                >
                    <span>Отладка стоимостей</span>
                    <button
                        type="button"
                        onClick={() => handleRecalculatePrices()}
                        className="btn btn-outline-primary btn-sm"
                    >
                        Запустить пересчет
                    </button>
                </h5>
            )}
            {!!nomenclaturePrices.length
                ? null
                : "У номенклатуры нет стоимостей в выбранном регионе"}

            <NomenclatureDetailPricesTable data={nomenclaturePrices} />
        </div>
    )
}
