import { AsyncPaginateProps } from 'react-select-async-paginate';
import { AsyncSelect } from "components/UI";
import { Link } from 'react-router-dom';

import styles from "./wrapperForAsyncSelect.module.scss"
import { Uuid } from 'types';

type SelectProps<ResponseType> = {
  dataUrl: string
  params?: object
  page?: number
  noMessage?: string
  loadMessage?: string
  noDefaultOption?: boolean
  isClearable?: boolean
  onChange: (v: any, noReset?: true) => void
  onFirstOptionLoad?: () => void
  skipValues?: Uuid[]
  externalOptions?: any[]
  filter?: (v: any) => any
  transformResponse?: (v: ResponseType) => any
  regionDependent?: boolean
  label: string;
  link?: {
    text: string,
    href: string
  }
} & Omit<AsyncPaginateProps<any, any, any, any>, "loadOptions" | "onChange">

export default function WrapperForAsyncSelect<ResponseType>({
  label,
  link,
  ...props
}: SelectProps<ResponseType>) {
  return (
    <div className={styles.wrapperSelect}>
      <label>{label}</label>
      <AsyncSelect {...props}/>
      {!!link && <a target="_blank" href={link.href}>{link.text}</a>}
    </div>
  );
};
