import { catalogRoutes } from "./Catalogs.routes"
import { classificationRoutes } from "./Classification.routes"
import { configuratorRoutes } from "./Configuration.routes"
import { economicRoutes } from "./Economic.routes"
import { graphicsRoutes } from "./Graphics.routes"
import { CreateRouteData } from "./tools"
import { userRoutes } from "./User.routes"

type RouteType = {
    folder?: string
    title: string
    routes: CreateRouteData[]
}

export const routes: RouteType[] = [
    {
        title: "Контроль доступа",
        folder: "control",
        routes: userRoutes,
    },
    {
        title: "Каталоги",
        folder: "catalog",
        routes: catalogRoutes,
    },
    {
        folder: "economics",
        title: "Экономика",
        routes: economicRoutes,
    },
    {
        title: "Конфигурация",
        folder: "configuration",
        routes: configuratorRoutes,
    },
    {
        title: "Классификаторы",
        folder: "classification",
        routes: classificationRoutes,
    },
    {
        title: "Сборщик графики",
        folder: "pictures",
        routes: graphicsRoutes,
    },
]

export const dismatchPersmissions = routes
    .reduce((acc, next) => [...acc, ...next.routes], [] as CreateRouteData[])
    .reduce((acc, next) => {
        if (next.permission) {
            acc[next.path] = next.permission
        }
        return acc
    }, {} as Record<string, string>)

console.log(dismatchPersmissions)

export const innerRoutes = routes.reduce(
    (acc, next) => [
        ...acc,
        ...next.routes.map((el) => ({ ...el, folder: next.folder })),
    ],
    [] as CreateRouteData[]
)

export const iframeRoutes = [configuratorRoutes]

export type RouterSlugs = (typeof innerRoutes)[number]["path"]
