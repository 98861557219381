import { usePageData } from "hooks/usePageData"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { uniqueEvents } from "../../helpers/uniqueEvents"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI/Required"
import TextError from "../UI/TextError"

const InputSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const { slug } = usePageData()
    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const optionHandler = (event: any) => {
        let value: any
        if (propertyContainer.get("multiple")) {
            value = []
            const option: any = Array.from(event.target.selectedOptions)
            option.forEach((element: any) => {
                value.push(element.value)
            })
        }
        value = value ? value : event.target.value

        dispatch(uniqueEvents(event, "OnChange", slug))
        dispatch(
            SET_CURRENT_VALUES({ [propertyContainer.getName()]: value }, group)
        )
    }

    useEffect(() => {
        dispatch(
            uniqueEvents(
                {
                    target: { value, id: propertyContainer.getName() },
                },
                "OnChange",
                slug
            )
        )
    }, [value])

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <select
                        className={`form-select ${isInvalid}`}
                        disabled={propertyContainer.get("readonly")}
                        value={value}
                        size={propertyContainer.get("size")}
                        style={{ width: "100%" }}
                        onChange={(event) => {
                            optionHandler(event)
                        }}
                        multiple={propertyContainer.get("multiple")}
                        name={propertyContainer.getName()}
                        id={propertyContainer.getName()}
                        aria-label="Default select"
                    >
                        {value === "" && (
                            <option disabled value="">
                                Выберите
                            </option>
                        )}
                        {propertyContainer.get("options").map((item: any) => {
                            if (item === null) return null

                            //TODO always object
                            if (typeof item !== "object") {
                                item = {
                                    value: item,
                                    label: item,
                                }
                            }

                            return (
                                <option key={item.value} value={item.value}>
                                    {item.label}
                                </option>
                            )
                        })}
                    </select>
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputSelect
