import { useQuery } from "@tanstack/react-query"
import { usePageData } from "hooks/usePageData"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { GetReferenceNomenclatureResponse } from "types/api"
import ContentForm from "../../components/ContentForm"
import { SaveMenu } from "../../components/UI/SaveMenu"
import api from "../../helpers/api"
import { isEmptyObject } from "../../helpers/isEmptyObject"

import {
    getCurrentValues,
    getFieldsFromServer,
    INIT_STATE_GROUP,
} from "../../store/fields/actions"
import "../../styles/components/nomenclature-calculation-log.sass"
import { AddToRebbit } from "./NomenclatureDetail.AddToRebbit"
import NomenclatureDetailAnalogues from "./NomenclatureDetail.Analogues"
import NomenclatureDetailCollections from "./NomenclatureDetail.Collections"
import { NomenclatureDetailPrices } from "./NomenclatureDetail.Prices"
import NomenclatureDetailRequesiteRegions from "./NomenclatureDetail.RequesiteRegions"

const saveFields = [
    "id",
    "name",
    "accountingName",
    "itemsGroup",
    "producer",
    "series",
    "model",
]

const FillingRow = ({
    attribute,
    value,
}: {
    attribute: string
    value: string
}) => {
    return (
        <div className="row mt-2">
            <label className="col-xl-4">{attribute}</label>
            <div className="col-xl-8 d-flex align-items-end">{value}</div>
        </div>
    )
}

export const NomenclatureDetail: React.FC = () => {
    const fields: object[] = useSelector(
        (state: { [key: string]: any }) => state.fields.initFields
    )
    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues?.["main"]
    )

    const attributeName: string = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues["main"]?.name
    )

    const { slug } = usePageData()

    const dispatch = useDispatch()
    const routerParams: { [key: string]: string } = useParams()

    const title: string = routerParams.id ? "Редактирование" : "Создание"

    const onModifySendData = React.useCallback((data: any) => {
        for (const code in data) {
            // * Clear ID
            if (code === "id") {
                delete data[code]
            }
            if (typeof data[code] === "object" && data[code]?.id) {
                data[code] = data[code].id
            }
            if (!saveFields.includes(code)) {
                delete data[code]
            }
        }
    }, [])

    useEffect(() => {
        document.title = title
        dispatch(INIT_STATE_GROUP({ main: {} }))

        dispatch(getFieldsFromServer(`reference/${slug}/fields`))
        dispatch(
            getCurrentValues(`reference/${slug}`, routerParams.id, "main", [
                "analogues",
                "analoguesFilters",
            ])
        )
    }, [])

    const { data: nomenclature } = useQuery<GetReferenceNomenclatureResponse>({
        queryKey: ["nomenclature", routerParams.id],
        queryFn: () =>
            api.getTyped("nomenclature/get", {
                id: routerParams.id,
            }),
    })

    const [pictureUrl, setPictureUrl] = useState(
        "https://fakeimg.pl/200x300/?retina=1&text=404&font=noto"
    )
    useQuery({
        queryKey: ["nomenclature-picture", routerParams.id],
        enabled: !!nomenclature,
        queryFn: () => api.getTyped(nomenclature!.pictureUrl, {}, true),
        onSuccess: (v) => setPictureUrl(nomenclature!.pictureUrl),
        onError: (v) =>
            setPictureUrl(
                "https://fakeimg.pl/200x300/?retina=1&text=404&font=noto"
            ),
    })

    return (
        <>
            <h1 className="h3 mb-3">
                {title}
                {routerParams.id
                    ? ` "${
                          (slug === "uploaded-attribute" && routerParams.id) ||
                          attributeName ||
                          "..."
                      }"`
                    : null}
            </h1>
            <SaveMenu url={`reference/${slug}`} onModify={onModifySendData} />
            <div className="control">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button
                            className="nav-link active"
                            id="edit-settings-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#edit-settings"
                            type="button"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            Настройка
                        </button>
                    </div>
                </nav>

                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>
                                    {isEmptyObject(currentValues) ? (
                                        <div>Загрузка...</div>
                                    ) : (
                                        <div>
                                            <ContentForm
                                                isCreate={!routerParams.id}
                                                group={"main"}
                                                fields={fields}
                                            />
                                            <NomenclatureDetailCollections
                                                collections={
                                                    nomenclature?.collections ??
                                                    []
                                                }
                                            />

                                            <NomenclatureDetailAnalogues />
                                        </div>
                                    )}
                                </div>
                                {nomenclature && (
                                    <div className="row">
                                        <div className="col">
                                            <div className="control-in">
                                                <h5>Наполнение</h5>

                                                <FillingRow
                                                    attribute="Модель"
                                                    value={
                                                        nomenclature.model.name
                                                    }
                                                />
                                                {nomenclature?.classificationAccordance && (
                                                    <FillingRow
                                                        attribute="ТНВЭД"
                                                        value={
                                                            nomenclature
                                                                .classificationAccordance
                                                                .tnved.name
                                                        }
                                                    />
                                                )}
                                                {nomenclature?.classificationAccordance && (
                                                    <FillingRow
                                                        attribute="ОКРБ"
                                                        value={
                                                            nomenclature
                                                                .classificationAccordance
                                                                .okrb.name
                                                        }
                                                    />
                                                )}
                                                <NomenclatureDetailRequesiteRegions
                                                    defaultValues={
                                                        nomenclature.nomenclatureRegionDiscontinued
                                                    }
                                                    targetKey="nomenclatureRegionDiscontinued"
                                                    nomenclatureId={
                                                        routerParams.id
                                                    }
                                                />

                                                <NomenclatureDetailRequesiteRegions
                                                    defaultValues={
                                                        nomenclature.nomenclatureRegionStock
                                                    }
                                                    targetKey="nomenclatureRegionStock"
                                                    nomenclatureId={
                                                        routerParams.id
                                                    }
                                                />

                                                {Object.entries(
                                                    nomenclature.renderedValues
                                                ).map(
                                                    (
                                                        [attribute, value],
                                                        idx
                                                    ) => (
                                                        <FillingRow
                                                            attribute={
                                                                attribute
                                                            }
                                                            value={value}
                                                            key={`row-${idx}`}
                                                        />
                                                    )
                                                )}
                                            </div>

                                            <NomenclatureDetailPrices
                                                nomenclature={nomenclature}
                                            />

                                            <AddToRebbit />
                                        </div>

                                        <div className="col">
                                            <div className="control-in col">
                                                <h5>Изображение</h5>

                                                <img
                                                    src={pictureUrl}
                                                    alt="Изображение двери"
                                                    style={{
                                                        maxWidth: "300px",
                                                        height: "auto",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SaveMenu url={`reference/${slug}`} onModify={onModifySendData} />
        </>
    )
}
