import { useQuery } from "@tanstack/react-query"
import { usePageData } from "hooks/usePageData"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import api from "../../helpers/api"
import { uniqueEvents } from "../../helpers/uniqueEvents"
import { SET_CURRENT_VALUES } from "../../store/fields/actions"
import { InputProps } from "../../types/interfaces"
import { Required } from "../UI"
import TextError from "../UI/TextError"

const InputItemsGroupSelect: React.FC<InputProps> = ({
    propertyContainer,
    textError,
    group,
}) => {
    const value = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues[group]?.[propertyContainer.getName()]
    )

    const { slug } = usePageData()
    const dispatch = useDispatch()

    const isInvalid = textError && "is-invalid"

    const optionHandler = (event: any) => {
        dispatch(uniqueEvents(event, "OnChange", slug))
        dispatch(
            SET_CURRENT_VALUES(
                {
                    [propertyContainer.getName()]: {
                        id: event.target.value,
                        alpha3: event.target.value,
                    },
                },
                group
            )
        )
    }

    const { data } = useQuery<string[]>({
        queryKey: ["listing/country"],
        queryFn: () =>
            api.getTyped("listing/country", {
                count_on_page: Number.MAX_SAFE_INTEGER,
                sort: "name",
            }),
    })

    const pickListingRecordField = (record: any, field: string) => {
        return record.fields.find((v: { name: string }) => v.name === field)
            ?.value
    }

    return (
        <>
            <div className="row mb-3">
                <label className="col-xl-3 col-form-label">
                    {propertyContainer.get("required") ? <Required /> : null}
                    {propertyContainer.get("label")}
                </label>
                <div className="col-xl-9">
                    <select
                        className={`form-select ${isInvalid}`}
                        disabled={propertyContainer.get("readonly")}
                        value={value?.alpha3 ?? ""}
                        size={propertyContainer.get("size")}
                        onChange={(event) => optionHandler(event)}
                        multiple={propertyContainer.get("multiple")}
                        name={propertyContainer.getName()}
                        id={propertyContainer.getName()}
                        aria-label="Default select"
                    >
                        <option value="">Выберите</option>

                        {((data as any)?.entities ?? []).map((item: any) => {
                            return (
                                <option
                                    key={pickListingRecordField(
                                        item,
                                        "alpha3Code"
                                    )}
                                    value={pickListingRecordField(
                                        item,
                                        "alpha3Code"
                                    )}
                                >
                                    {pickListingRecordField(item, "name")}
                                </option>
                            )
                        })}
                    </select>
                    {textError && <TextError text={textError} />}
                </div>
            </div>
        </>
    )
}

export default InputItemsGroupSelect
