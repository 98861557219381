import { useMemo } from "react"
import { useLocation } from "react-router-dom"

export const usePageData = () => {
    const location = useLocation()
    const slugs = location.pathname.split("/").filter(Boolean)
    const folder = slugs[0]
    const slug = slugs[1]
    const apiPath = "listing/" + slug

    const listingActionName = useMemo(() => {
        switch (slug) {
            case "compatible-collection":
                return "Создать коллекцию"
            default:
                return "Создать свойство"
        }
    }, [location])
    const isUpdatable =
        location.pathname.includes("create") ||
        location.pathname.includes("update")

    return {
        folder,
        slug,
        listingActionName,
        apiPath,
        routeBasePath: folder + "/" + slug,
        isUpdatable,
    }
}
