import { useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import React from "react"
import { Uuid } from "types"
import { ConfiguratorParams } from "./index"

import useDebouce from "hooks/useDebouce"

export const ConfiguratorPartNaming: React.FC<{
    params: ConfiguratorParams
    filled: {
        [attributeId: Uuid]: string
    }
}> = ({ params, filled }) => {
    const deboucedQuery = useDebouce([params, filled], 1000)

    const { data } = useQuery<string>({
        queryKey: deboucedQuery,
        enabled: !!Object.keys(filled).length,
        queryFn: () =>
            api
                .post<Object, string>(
                    "v1/nomenclature/name-preview",
                    {},
                    {
                        ...params,
                        model: params.model?.value,
                        values: filled,
                    }
                )
                .then((v) => v.data),
    })

    return (
        <>
            <div
                className="control-in"
                style={{
                    borderRadius: 0,
                    margin: 0,
                    zIndex: 99999,
                    position: "relative",
                }}
            >
                <div className="row">
                    <h5 className="m-0 col-xl-3">Наименование</h5>

                    <div className="col-xl-9" style={{ paddingLeft: 45 }}>
                        <input
                            className="form-control"
                            type="text"
                            disabled={true}
                            value={data}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
