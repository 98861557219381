import { usePageData } from "hooks/usePageData"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { useNotifications } from "../../../hooks/useNotifications"
import { eventSelecter } from "../eventSelecter"
import { ListItemComponentSelecter } from "../ListComponentSelecter"

/**
 *
 * @param sortFields - сортирует по полям, что бы поля в заголовке таблицы совпадали с полями в строках
 *
 */

export const TableRow = ({ selectedForRemove, autoRemove }: any) => {
    const [isCheckedRow, setIsCheckedRow] = React.useState<any>({})

    const { showNotification } = useNotifications()

    const dispatch = useDispatch()
    const tableHeaders =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.headers
        ) || []
    const entities =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.entities
        ) || []
    const settings =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.showedColumns
        ) || []
    const selectAll = useSelector(
        (state: { [key: string]: any }) => state.selectStatus.status
    )

    const location: { [key: string]: any } = useLocation()
    const history: { [key: string]: any } = useHistory()
    const { slug } = usePageData()

    const fileId = useSelector(
        (state: { [key: string]: any }) =>
            state.fields.currentValues.listing?.fileId
    )

    const sortFields = (headers: [], fields: any) => {
        const sortingArr = headers.map(
            (header: { [key: string]: any }) => header.columnName
        )
        return fields.sort(
            (a: any, b: any) =>
                sortingArr.indexOf(a.name) - sortingArr.indexOf(b.name)
        )
    }

    const entitiesCheckboxes = entities.reduce((acc: any, inputObj: any) => {
        acc[inputObj.id] = false
        return acc
    }, {})

    const [checkboxStatus, setCheckboxStatus] = useState(entitiesCheckboxes)

    const createObject = (array: any[], flag: boolean) => {
        return array.reduce((acc: any, inputObj: any) => {
            acc[inputObj] = flag
            return acc
        }, {})
    }

    useEffect(() => {
        const entitiesIDs = entities.map((item: any) => item.id)
        if (selectAll) {
            const res = createObject(entitiesIDs, selectAll)
            setCheckboxStatus(res)
            setIsCheckedRow(res)
            autoRemove(selectAll, entitiesIDs)
        }
        if (!selectAll) {
            const res = createObject(entitiesIDs, selectAll)
            setCheckboxStatus(res)
            setIsCheckedRow(res)
            autoRemove(selectAll, entitiesIDs)
        }
    }, [selectAll, entities])

    return entities.map((item: any) => {
        return (
            <tr key={item.id} className={isCheckedRow[item.id] ? "active" : ""}>
                <td>
                    <input
                        className="form-check-input"
                        id={item.id}
                        type="checkbox"
                        checked={checkboxStatus[item.id] || false}
                        onChange={(event) => {
                            const isCheckedRowCopy = isCheckedRow
                            if (event.target.checked === true) {
                                setCheckboxStatus({
                                    ...checkboxStatus,
                                    [item.id]: true,
                                })
                                setIsCheckedRow({
                                    ...isCheckedRow,
                                    [item.id]: event.target.checked,
                                })
                            }
                            if (event.target.checked === false) {
                                delete isCheckedRowCopy[item.id]
                                setCheckboxStatus({
                                    ...checkboxStatus,
                                    [item.id]: false,
                                })
                                setIsCheckedRow(isCheckedRowCopy)
                            }
                            selectedForRemove(event, item)
                        }}
                    />
                </td>
                <td className="users-td-action">
                    <div className="btn-group dropend">
                        <button
                            type="button"
                            className="users-dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-list"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                                ></path>
                            </svg>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                {item?.actions
                                    .filter(
                                        (el: any) => el.type !== "toArchive"
                                    )
                                    .map((action: any) => {
                                        return (
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                                key={action.name}
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    e.stopPropagation()
                                                    eventSelecter(
                                                        action.type,
                                                        item.id,
                                                        action.action,
                                                        fileId,
                                                        history,
                                                        slug,
                                                        dispatch,
                                                        history,
                                                        showNotification
                                                    )
                                                }}
                                                disabled={
                                                    slug === "user" &&
                                                    item.id === 1 &&
                                                    action.type === "delete"
                                                }
                                            >
                                                {action.label}
                                            </button>
                                        )
                                    })}
                            </li>
                        </ul>
                    </div>
                </td>
                {sortFields(
                    settings.length === 0 ? tableHeaders : settings,
                    item.fields
                ).map((data: any) => {
                    if (
                        settings.some(
                            (item: { [key: string]: any }) =>
                                item.columnName === data.name
                        )
                    ) {
                        return (
                            <td key={`${data.name}-${item.id}`}>
                                <ListItemComponentSelecter
                                    id={item.id}
                                    {...data}
                                    page={location.pathname}
                                />
                            </td>
                        )
                    }
                    return null
                })}
            </tr>
        )
    })
}
