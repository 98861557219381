import { DefaultOptionType } from "antd/es/select";
import api from "helpers/api";
import { useEffect, useState } from "react";
import { ValueEditorProps } from "react-querybuilder";
import { AsyncPaginate as AsyncSelect } from "react-select-async-paginate";
import { KitItemData } from "./types";

export default function KitsItemsValueSelector(props: ValueEditorProps) {
  const { value, handleOnChange, field, context } = props;
  const _context = context as { item: KitItemData };
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const loadedOptions = async (
    search: string,
    _: any,
    additional: { page: number } | undefined
  ) => {
    const page = additional?.page ?? 1;
    setLoading(true);
    try {
      const response = await api.getTyped<Record<string, string>>(
        "v1/query-builder/kits/values-list",
        {
          attribute: field,
          "items-group": _context.item.group?.value,
          query: search,
          page: page,
          count_on_page: 200,
        }
      );
      const options = Object.entries(response).map(
        ([key, value]) =>
          ({
            label: value,
            value: key,
          } as DefaultOptionType)
      );
      setOptions((prev) => [...prev, ...options]);
      return {
        options: options,
        hasMore: options.length >= 200,
        additional: {
          page: page + 1,
        },
      };
    } catch (err: any) {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadedOptions("", undefined, undefined);
  }, []);

  const ensureValueLoaded = async () => {
    let page = 2;
    while (!options.find((el) => el.value === value)) {
      const response = await loadedOptions("", undefined, { page });
      if (
        !response.hasMore ||
        !response.options.length ||
        response.options.some((el) => el.value === value)
      ) {
        break;
      }
      page += 1;
    }
  };

  useEffect(() => {
    if (
      value &&
      !options.some((el) => el.value === value) &&
      !!options.length
    ) {
      ensureValueLoaded();
    }
  }, [value, options]);

  return (
    <AsyncSelect
      key={field}
      loadOptionsOnMenuOpen
      className="fw"
      debounceTimeout={500}
      placeholder="Выберите..."
      loadingMessage={({ inputValue }) => {
        return (
          <span>{inputValue ? `Поиск : "${inputValue}"` : "Загрузка..."}</span>
        );
      }}
      value={value ? options.find((el) => el.value === value) : undefined}
      reduceOptions={(prevOption, loadedOptions, additional) => {
        return [...prevOption, ...loadedOptions];
      }}
      loadOptions={loadedOptions}
      isLoading={loading}
      onChange={(e: any) => {
        handleOnChange(e?.value);
      }}
      isSearchable
      pageSize={200}
    />
  );
}
