import { useCallback, useMemo } from "react"
import { useSelector } from "react-redux"
import { allowedPages } from "routes/allowedPages"
import { dismatchPersmissions } from "routes/routes"
import { RootState } from "store/types"
import { usePageData } from "./usePageData"

type Permission = {
    view: boolean
    update: boolean
}

export const usePermission = () => {
    const credentials =
        useSelector((state: RootState) => state.user.user?.credentials) ??
        ([] as string[])

    const { slug, folder } = usePageData()

    const checkCredentials = useCallback(
        (slug: string, permissionAction: "view" | "update") => {
            const permission = dismatchPersmissions[slug]
            const permissionKey = permission ?? slug // Use for case if slug of page doesn't match a credential;
            if (allowedPages.includes(permissionKey)) return true
            return !!credentials.find(
                (el) =>
                    el === `${permissionKey}.${permissionAction}` ||
                    el === `${permissionKey}.${permissionAction}` ||
                    el === permissionKey
            )
        },
        [credentials]
    )

    const permission: Permission = useMemo(() => {
        return {
            view: checkCredentials(slug, "view"),
            update: checkCredentials(slug, "update"),
        }
    }, [credentials, slug, folder])

    return {
        permission,
        credentials,
        checkCredentials,
    }
}
