import { Input, Tooltip } from "antd"
import { InputProps } from "antd/lib"
import { useState } from "react"
import { Uuid } from "types"

interface IPropertiesInputProps<T>
    extends Pick<InputProps, "id" | "required" | "onBlur" | "defaultValue"> {
    error?: {
        isError: boolean
        message: string
    } | null
    attribute: keyof T
    setValue: React.Dispatch<
        React.SetStateAction<
            | []
            | (T & {
                  id: Uuid
                  error?: {
                      isError: boolean
                      message: string
                  } | null
              })[]
        >
    >
    sendValue: (val?: string | number) => void
    value?: string | number
}

const getTextError = ({
    isEmpty,
    isDuplicate,
    isNumericError,
}: {
    isEmpty: boolean
    isDuplicate: boolean
    isNumericError: boolean
}) => {
    if (isEmpty) {
        return "Не заполнена очередность у свойств на серии"
    }

    if (isDuplicate) {
        return "Значения не должны повторяться"
    }

    if (isNumericError) {
        return "Введите числовое значение"
    }

    return ""
}

const PropertiesInput = <T,>({
    setValue,
    attribute,
    value,
    sendValue,
    error,
    id,
}: IPropertiesInputProps<T>) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false)

    const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value

        const parsedValue = isNaN(Number(val)) || val === "" ? val : Number(val)

        setValue((state) =>
            state.map((item) => ({
                ...item,
                [attribute]: item.id === id ? parsedValue : item[attribute],
            }))
        )
    }

    const onBlur = () => {
        setValue((state) =>
            state?.map((item) => {
                const currentValue = item[attribute]
                const isDuplicate =
                    state.filter(
                        (subitem) => subitem[attribute] === currentValue
                    ).length > 1
                const isNumericError = Number.isNaN(Number(currentValue))
                const isEmpty =
                    currentValue?.toString().trim() === "" ||
                    currentValue === null
                const isError = isEmpty || isDuplicate || isNumericError

                const message = getTextError({
                    isDuplicate,
                    isEmpty,
                    isNumericError,
                })

                if (!isError && item.id === id) {
                    sendValue(value)
                }

                return {
                    ...item,
                    error: {
                        isError: isEmpty || isDuplicate || isNumericError,
                        message: message,
                    },
                }
            })
        )
    }

    const onFocus = () => {
        setValue((state) =>
            state.map((item) => ({
                ...item,
                error: item.id === id ? null : item.error,
            }))
        )
    }

    return (
        <Tooltip
            placement="left"
            title={error?.message}
            open={error?.isError && isTooltipVisible}
        >
            <Input
                value={value}
                onChange={changeValue}
                onBlur={onBlur}
                onFocus={onFocus}
                status={error?.isError ? "error" : ""}
                onMouseEnter={() => setTooltipVisible(true)}
                onMouseLeave={() => setTooltipVisible(false)}
            />
        </Tooltip>
    )
}

export default PropertiesInput
