import { SelectEntity } from "components/Selects/types"
import dayjs, { Dayjs } from "dayjs"
import { AttributeLink, Uuid } from "types"
import { create } from "zustand"
import { OperationType } from "./CostCollectorSelectRowsAction"
import { applyPrecision } from "./helpers"
import { TablePriceItem } from "./priceDataProps"
import { Currency, PriceData } from "./СostCollectorModelTable"

export type ChangeModelAction = "newPrice" | "markupValue" | "markupPercent"

export type Precision = -2 | -1 | 0 | 1 | 2

type FocusedCell = {
    id: Uuid
    cellType: ChangeModelAction
} | null
interface CostCollectorStore {
    itemsGroup: SelectEntity | null
    setItemsGroup: (data: SelectEntity | null) => void
    producer: SelectEntity | null
    setProducer: (data: SelectEntity | null) => void
    selectedRows: TablePriceItem[]
    toggleRow: (data: TablePriceItem) => void
    resetValue: Symbol
    resetGroupAndProducer: () => void
    selectedAttribute: AttributeLink | null
    setSelectedAttribute: (data: AttributeLink | null) => void
    seriesTableData: TablePriceItem[]
    setSeriesTableData: (data: PriceData) => void
    showSendModal: () => void
    hideSendModal: () => void
    modelDate: Dayjs | null
    setModelDate: (v: Dayjs | null) => void
    modelPrecision: Precision
    setModelPrecision: (v: Precision) => void
    modelOperationType: OperationType | null
    setModelOperationType: (v: OperationType | null) => void
    sendModalVisible: boolean
    currentCurrency: Currency | null
    setCurrentCurrency: (v: Currency | null) => void
    changeModel: (id: Uuid, key: ChangeModelAction, value: number) => void
    massChangeModelData: (
        key: ChangeModelAction,
        value: number,
        precision: Precision
    ) => void
    afterRefetchModels: () => void
    toggleAllModels: () => void
    showHistoryModal: TablePriceItem | null
    toggleHistoryModal: (v: TablePriceItem | null) => void
    focusedCell: FocusedCell
    focusNextCell: () => void
    focusPrevCell: () => void
    focusNextRow: () => void
    focusPrevRow: () => void
    setActiveCell: (data: FocusedCell) => void
    expandedRowKeys: Uuid[]
    setExpandedRowKeys: (data: Uuid[]) => void
    applied: boolean
    setApplied: (v: boolean) => void
}

const transformPriceData = (data: PriceData): TablePriceItem[] => {
    const { currency, data: series } = data
    const items: TablePriceItem[] = []

    series.forEach((seriesItem) => {
        const seriesItems: TablePriceItem[] = [
            {
                id: seriesItem.series.id,
                name: seriesItem.series.name,
                innerRowIds: [],
            },
        ]
        seriesItem.modelsPrices.forEach((modelPrice) => {
            seriesItems[0].innerRowIds.push(modelPrice.id)
            seriesItems.push({
                id: modelPrice.model.id,
                modelRowId: modelPrice.id,
                model: modelPrice.model,
                name: modelPrice.model.name,
                parentId: seriesItem.series.id,
                oldPrice: modelPrice.value,
                currency: currency,
                innerRowIds: [modelPrice.id],
            })
        })
        items.push(...seriesItems)
    })
    return items
}

const changeModelNewPrice = (
    item: TablePriceItem,
    newPrice: number,
    precision?: Precision
): TablePriceItem => {
    return {
        ...item,
        newPrice: applyPrecision(newPrice, precision ?? -2),
        markupValue: undefined,
        markupPercent: undefined,
    }
}

const changeModelMarkupValue = (
    item: TablePriceItem,
    markupValue: number,
    precision?: Precision
): TablePriceItem => {
    const { oldPrice = 0, markupPercent = 0 } = item
    const priceWithMarkup = oldPrice + markupValue
    const newPrice =
        oldPrice + markupValue + (priceWithMarkup * markupPercent) / 100
    return {
        ...item,
        newPrice: applyPrecision(newPrice, precision),
        markupValue,
    }
}

const changeModelMarkupPercent = (
    item: TablePriceItem,
    markupPercent: number,
    precision?: Precision
): TablePriceItem => {
    const { oldPrice = 0, markupValue = 0 } = item
    const priceWithMarkup = oldPrice + markupValue
    const newPrice =
        oldPrice + markupValue + (priceWithMarkup * markupPercent) / 100
    return {
        ...item,
        markupPercent,
        newPrice: applyPrecision(newPrice, precision),
    }
}

const changeModelData = (
    id: Uuid,
    key: ChangeModelAction,
    value: number,
    rows: TablePriceItem[],
    precision?: Precision
): TablePriceItem[] => {
    const targetId = rows.findIndex((el) => el.id === id)
    if (targetId < 0) return rows

    const target = rows[targetId]

    switch (key) {
        case "newPrice":
            rows[targetId] = changeModelNewPrice(target, value, precision ?? -2)
            break
        case "markupPercent":
            rows[targetId] = changeModelMarkupPercent(
                target,
                value,
                precision ?? -2
            )
            break
        case "markupValue":
            rows[targetId] = changeModelMarkupValue(
                target,
                value,
                precision ?? -2
            )
            break
    }

    return [...rows]
}

const massChangeModelData = (
    selectedRows: TablePriceItem[],
    key: ChangeModelAction,
    value: number,
    rows: TablePriceItem[],
    precision?: Precision
) => {
    const changedModels: TablePriceItem[] = selectedRows
        .filter((el) => el.parentId)
        .reduce((acc, row) => {
            return changeModelData(row.id, key, value, acc, precision)
        }, rows as TablePriceItem[])
    return changedModels
}

const focusNextCell = (
    rows: TablePriceItem[],
    focusedCell: FocusedCell
): FocusedCell => {
    if (!focusedCell && rows[0]) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }
    const currentRowId = rows.findIndex((el) => el.id === focusedCell?.id)

    const returnNextRow = (): FocusedCell => {
        const nextRow = rows[currentRowId + 1]
        if (!nextRow) return focusedCell
        return {
            id: nextRow.id,
            cellType: "newPrice",
        }
    }

    if (currentRowId < 0) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }
    const rowMembers: ChangeModelAction[] = [
        "newPrice",
        "markupValue",
        "markupPercent",
    ]
    const rowMemderIndex = rowMembers.findIndex(
        (el) => el === focusedCell?.cellType
    )
    if (rowMembers[rowMemderIndex + 1]) {
        return {
            id: focusedCell!.id,
            cellType: rowMembers[rowMemderIndex + 1],
        }
    }
    return returnNextRow()
}

const focusNextRow = (
    rows: TablePriceItem[],
    focusedCell: FocusedCell
): FocusedCell => {
    if (!focusedCell && rows[0]) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }

    const currentRowId = rows.findIndex((el) => el.id === focusedCell?.id)
    if (currentRowId < 0) {
        return {
            id: rows[0].id,
            cellType: focusedCell?.cellType ?? "newPrice",
        }
    }
    const nextRowIndex =
        currentRowId + 1 > rows.length - 1 ? rows.length - 1 : currentRowId + 1

    return {
        id: rows[nextRowIndex].id,
        cellType: focusedCell?.cellType ?? "newPrice",
    }
}

const focusPrevRow = (
    rows: TablePriceItem[],
    focusedCell: FocusedCell
): FocusedCell => {
    if (!focusedCell && rows[0]) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }

    const currentRowId = rows.findIndex((el) => el.id === focusedCell?.id)
    if (currentRowId < 0) {
        return {
            id: rows[0].id,
            cellType: focusedCell?.cellType ?? "newPrice",
        }
    }
    const prevRouwIndex = currentRowId - 1 < 0 ? 0 : currentRowId - 1

    return {
        id: rows[prevRouwIndex].id,
        cellType: focusedCell?.cellType ?? "newPrice",
    }
}

const focusPrevCell = (
    rows: TablePriceItem[],
    focusedCell: FocusedCell
): FocusedCell => {
    if (!focusedCell && rows[0]) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }
    const currentRowId = rows.findIndex((el) => el.id === focusedCell?.id)

    const returnPrevRow = (): FocusedCell => {
        const prevRow = rows[currentRowId - 1]
        if (!prevRow) return focusedCell
        return {
            id: prevRow.id,
            cellType: "markupPercent",
        }
    }

    if (currentRowId < 0) {
        return {
            id: rows[0].id,
            cellType: "newPrice",
        }
    }
    const rowMembers: ChangeModelAction[] = [
        "newPrice",
        "markupValue",
        "markupPercent",
    ]
    const rowMemderIndex = rowMembers.findIndex(
        (el) => el === focusedCell?.cellType
    )
    if (rowMembers[rowMemderIndex - 1]) {
        return {
            id: focusedCell!.id,
            cellType: rowMembers[rowMemderIndex - 1],
        }
    }
    return returnPrevRow()
}

const compareSelectedRows = (
    selectedRows: TablePriceItem[],
    tableData: TablePriceItem[]
) => {
    return selectedRows.map(
        (selectedRow) =>
            tableData.find((el) => el.id === selectedRow.id) ?? selectedRow
    )
}

export const useCostCollectorStore = create<CostCollectorStore>((set) => ({
    itemsGroup: null,
    producer: null,
    selectedRows: [],
    resetValue: Symbol(),
    selectedAttribute: null,
    seriesTableData: [],
    sendModalVisible: false,
    modelPrecision: 0,
    modelOperationType: null,
    modelDate: null,
    currentCurrency: null,
    showHistoryModal: null,
    focusedCell: null,
    expandedRowKeys: [],
    applied: false,
    setItemsGroup: (data) =>
        set((state) => ({
            ...state,
            itemsGroup: data,
            applied: false,
            selectedRows: [],
            modelDate: null,
        })),
    setProducer: (data) =>
        set((state) => ({
            ...state,
            producer: data,
            applied: false,
            selectedRows: [],
            modelDate: null,
        })),
    toggleRow: (data) => {
        set((state) => {
            if (!data.parentId) {
                const isChecked = state.selectedRows.some(
                    (el) => el.parentId === data.id
                )
                return {
                    ...state,
                    selectedRows: isChecked
                        ? state.selectedRows.filter(
                              (el) => el.parentId !== data.id
                          )
                        : [
                              ...state.selectedRows,
                              ...state.seriesTableData.filter(
                                  (el) => el.parentId === data.id
                              ),
                          ],
                }
            } else {
                const isChecked = state.selectedRows.some(
                    (el) => el.id === data.id
                )
                return {
                    ...state,
                    selectedRows: isChecked
                        ? state.selectedRows.filter((el) => el.id !== data.id)
                        : [...state.selectedRows, data],
                }
            }
        })
    },
    resetGroupAndProducer: () =>
        set((state) => ({
            ...state,
            itemsGroup: null,
            producer: null,
            resetValue: Symbol(),
            applied: false,
        })),
    setSelectedAttribute: (data) =>
        set((state) => ({
            ...state,
            selectedAttribute: data,
        })),
    setSeriesTableData: (data) =>
        set((state) => {
            const newTableData = transformPriceData(data)
            return {
                ...state,
                seriesTableData: newTableData,
                currentCurrency: data.currency,
            }
        }),
    changeModel: (id, key, value) =>
        set((state) => {
            const newTableData = [
                ...changeModelData(id, key, value, state.seriesTableData),
            ]
            return {
                ...state,
                seriesTableData: newTableData,
                selectedRows: compareSelectedRows(
                    state.selectedRows,
                    newTableData
                ),
            }
        }),
    massChangeModelData: (key, value, rounding) =>
        set((state) => {
            const newTableData = massChangeModelData(
                state.selectedRows,
                key,
                value,
                state.seriesTableData,
                rounding
            )
            return {
                ...state,
                seriesTableData: newTableData,
                selectedRows: compareSelectedRows(
                    state.selectedRows,
                    newTableData
                ),
                modelPrecision: rounding,
            }
        }),
    showSendModal: () =>
        set((state) => ({
            ...state,
            sendModalVisible: true,
        })),
    hideSendModal: () =>
        set((state) => ({
            ...state,
            sendModalVisible: false,
        })),
    setModelPrecision: (v: Precision) =>
        set((state) => ({
            ...state,
            modelPrecision: v,
        })),
    setModelDate: (v: Dayjs | null) =>
        set((state) => ({
            ...state,
            modelDate: v,
        })),
    setCurrentCurrency: (v: Currency | null) =>
        set((state) => ({
            ...state,
            currentCurrency: v,
        })),
    setModelOperationType: (v: OperationType | null) =>
        set((state) => ({
            ...state,
            modelOperationType: v,
        })),
    afterRefetchModels: () =>
        set((state) => ({
            ...state,
            modelPrecision: 0,
            selectedRows: [],
        })),
    toggleAllModels: () => {
        return set((state) => {
            const newSelectedRows = state.selectedRows.length
                ? []
                : state.seriesTableData.filter((el) => !!el.parentId)
            return {
                ...state,
                selectedRows: newSelectedRows,
            }
        })
    },
    toggleHistoryModal: (v) =>
        set((state) => ({
            ...state,
            showHistoryModal: v,
        })),
    focusNextCell: () => {
        return set((state) => ({
            ...state,
            focusedCell: focusNextCell(
                state.seriesTableData.filter((el) =>
                    state.expandedRowKeys.includes(el.parentId ?? "")
                ),
                state.focusedCell
            ),
        }))
    },
    focusPrevCell: () => {
        return set((state) => ({
            ...state,
            focusedCell: focusPrevCell(
                state.seriesTableData.filter((el) =>
                    state.expandedRowKeys.includes(el.parentId ?? "")
                ),
                state.focusedCell
            ),
        }))
    },
    setActiveCell: (data) =>
        set((state) => ({
            ...state,
            focusedCell: data,
        })),
    focusNextRow: () =>
        set((state) => ({
            ...state,
            focusedCell: focusNextRow(
                state.seriesTableData.filter((el) =>
                    state.expandedRowKeys.includes(el.parentId ?? "")
                ),
                state.focusedCell
            ),
        })),
    focusPrevRow: () =>
        set((state) => ({
            ...state,
            focusedCell: focusPrevRow(
                state.seriesTableData.filter((el) =>
                    state.expandedRowKeys.includes(el.parentId ?? "")
                ),
                state.focusedCell
            ),
        })),
    setExpandedRowKeys: (data) =>
        set((state) => ({
            ...state,
            expandedRowKeys: data,
        })),
    setApplied(v) {
        return set((state) => ({
            ...state,
            applied: v,
            modelDate: dayjs(new Date()),
        }))
    },
}))
