import { useQuery } from "@tanstack/react-query"
import { Col, InputNumber, Row, Typography } from "antd"
import clsx from "clsx"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useDetailData } from "hooks/useDetailData"
import { useNotifications } from "hooks/useNotifications"
import { useRef, useState } from "react"
import { AttributeListItem, Uuid } from "types"
import { Attribute } from "types/api"

export default function ProducerPriceSet({
    groupId,
    regionId,
}: {
    groupId?: Uuid
    regionId?: Uuid
}) {
    const [selectedRow, setSelectedRow] = useState<Attribute | null>(null)

    const { showNotification } = useNotifications()

    const { id: producerId } = useDetailData()

    const {
        data,
        isFetching,
        dataUpdatedAt: linksUpdatedAt,
    } = useQuery<AttributeListItem["attribute"][]>({
        queryKey: [groupId, producerId],
        enabled: !!groupId && !!producerId,
        refetchOnWindowFocus: false,
        queryFn: () =>
            api
                .getTyped<AttributeListItem[]>(
                    `v1/group/${groupId}/producer/${producerId}/attributes/links`
                )
                .then((data) => data.map((el) => el.attribute)),
        initialData: [],
    })

    const {
        data: existingOrders,
        refetch: refetchExistingOrders,
        dataUpdatedAt,
    } = useQuery({
        queryKey: ["existing-links", groupId, regionId],
        enabled: !!groupId && !!regionId, //Временно выключил нужно бек
        queryFn: () =>
            api.getTyped<any[]>(`v1/economics/producer/attribute-order`, {
                producer: producerId,
                region: regionId,
                group: groupId,
            }),
        initialData: [],
    })

    const handleSaveOrder = (attributeValue: Uuid, value: number) => {
        const method = (value ? api.put : api.post).bind(api)
        const deletePart = value ? "" : "/delete"
        method(
            "v1/economics/producer/attribute-order" + deletePart,
            {},
            {
                producer: producerId,
                region: regionId,
                group: groupId,
                attribute: attributeValue,
                value: value || undefined,
            }
        )
            .then(() => {
                refetchExistingOrders()
                showNotification({
                    message: "Обновлено",
                    type: "success",
                })
            })
            .catch((error: any) => {
                showNotification({
                    message: error.message,
                    type: "danger",
                })
            })
    }

    const focusRefValue = useRef<string>("")

    return (
        <Flex.Col
            gap={20}
            fullWidth
            className={clsx({
                "--visible": !!groupId && !!regionId,
                "--hidden": !groupId || !regionId,
            })}
        >
            <Row className="fw" gutter={[10, 10]}>
                <Col xl={24} xxl={24}>
                    <Typography.Title
                        level={5}
                        className="fw text-align-start mb-2"
                    >
                        Порядок расчета цены
                    </Typography.Title>

                    <UTable
                        key={`${linksUpdatedAt}-${dataUpdatedAt}`}
                        data={data || []}
                        loading={isFetching}
                        onRowClick={setSelectedRow}
                        search
                        searchCondition={(row, search) =>
                            row.name
                                .toLowerCase()
                                .includes(search.toLowerCase())
                        }
                        isRowActive={(row) => {
                            return selectedRow?.id === row.id
                        }}
                        columns={[
                            {
                                columnName: "ID",
                                render: (row) => row.id,
                                width: 20,
                            },
                            {
                                columnName: "Свойство",
                                render: (row) => row.name,
                            },
                            {
                                columnName: "Порядок расчета",
                                width: 20,
                                render: (row) => {
                                    const defaultValue = existingOrders?.find(
                                        (v) => v.attribute?.id == row.id
                                    )?.order

                                    return (
                                        <div className="d-flex">
                                            <InputNumber
                                                bordered={false}
                                                controls={false}
                                                onFocus={(e) => {
                                                    focusRefValue.current =
                                                        e.target.value
                                                }}
                                                type="number"
                                                defaultValue={
                                                    defaultValue || ""
                                                }
                                                formatter={(value) => {
                                                    if (value === 0) {
                                                        return ""
                                                    }
                                                    return value
                                                }}
                                                min={0}
                                                max={100000}
                                                className="fw"
                                                onBlur={(e) => {
                                                    const currentValue =
                                                        e.target.value
                                                    if (
                                                        currentValue !==
                                                        focusRefValue.current
                                                    ) {
                                                        handleSaveOrder(
                                                            row.id,
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    }
                                                    focusRefValue.current = ""
                                                }}
                                            />
                                        </div>
                                    )
                                },
                            },
                        ]}
                    />
                </Col>
            </Row>
        </Flex.Col>
    )
}
