import { SyncOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import {
    Button,
    List,
    Modal,
    Pagination,
    Progress,
    Table,
    Typography,
} from "antd"
import Link from "antd/es/typography/Link"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { getDateString } from "helpers/getDateString"
import { useNotifications } from "hooks/useNotifications"
import { useRegion } from "hooks/useRegion"
import { Currency } from "pages/CostCollector/СostCollectorModelTable"
import { useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { Uuid } from "types"
import { NomenclatureItem, UserData } from "types/api"
import { usePriceRecalculateState } from "./PriceRecalculate.state"

type RecalculateData = {
    progress: number
    pricesPerSecond: number
    isReindexing: boolean
    data: {
        createdAt: string
        errorLog: { errors: string[] }
        id: Uuid
        skipped: number
        successful: number
        total: number
        updatedAt: string
        owner: UserData
    }
    prices: Array<{
        currency: Currency
        deliverySum: number
        markupPercentage: number
        otherExpensesSum: number
        nomenclature: string
        nomenclatureId?: Uuid
        packingSum: number
        price: number
        priceIncludesVAT: boolean
        priceWithVat: number
        provider: {
            id: Uuid
            name: string
        }
        signProvider: boolean
        startAt: string
        typeOfPrice: string | null
        updatedAt: string
    }>
}

type Paginator = {
    allPages: number
    currentPage: number
    pageSize: number
    totalCount: number
}

type GetNomenclatureRequest = {
    page: number
    countOnPage: number
    payload: {
        query?: RuleGroupType<any>
    }
}

export type NomenclatureResponse = {
    entityList: NomenclatureItem[]
    paginator: Paginator
}

const defaultPaginator = {
    allPages: 0,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
}

export default function PriceRecalculateModal({
    onSuccess,
}: {
    onSuccess: () => void
}) {
    const [paginator, setPaginator] = useState<Paginator>(defaultPaginator)
    const [recalculateData, setRecalculateData] = useState<Uuid | null>(null)
    const [saveLoading, setSetLoading] = useState(false)

    const { activeRegion } = useRegion()

    const {
        query,
        setOpenModal,
        modalOpened,
        updateDiscontinued,
        dateString,
        priceTypes,
        setTotalFound,
        totalFound,
    } = usePriceRecalculateState()

    const { handleApiError, showNotification } = useNotifications()

    const { data, remove } = useQuery<RecalculateData>({
        queryKey: [
            "get-recalculate-data",
            recalculateData,
            totalFound,
            modalOpened,
        ],
        enabled: !!recalculateData && !!totalFound && !!modalOpened,
        refetchInterval: (data) => ((data?.progress ?? 0) < 100 ? 2500 : false),
        queryFn: () =>
            api.getTyped<RecalculateData>(
                "nomenclature/recalculate-data/" + recalculateData
            ),
    })

    const {
        data: found,
        isFetching,
        refetch,
    } = useQuery<NomenclatureResponse>({
        queryKey: [paginator.currentPage, paginator.pageSize, query],
        enabled:
            !!query?.rules?.length && query?.rules?.every((el) => el.value),
        queryFn: () =>
            api
                .post<GetNomenclatureRequest, NomenclatureResponse>(
                    "v1/query-builder/price-nomenclature-filter/result",
                    {},
                    {
                        page: paginator.currentPage,
                        countOnPage: paginator.pageSize,
                        payload: {
                            query: query,
                        },
                    }
                )
                .then(({ data }) => {
                    setPaginator(data.paginator)
                    setTotalFound(data.paginator.totalCount)
                    return data
                }),
    })

    const save = async () => {
        setSetLoading(true)
        try {
            const response = await api.post<
                any,
                {
                    recalculateData: Uuid
                }
            >(
                "nomenclature/update-price",
                {},
                {
                    date: dateString(),
                    query,
                    priceTypes: priceTypes?.map((el) => el.value),
                    updateDiscontinued,
                    region: activeRegion?.value,
                }
            )
            if (response.data.recalculateData) {
                setRecalculateData(response.data.recalculateData)
            } else {
                setRecalculateData(null)
            }
        } catch (error: any) {
            handleApiError(error)
            setRecalculateData(null)
        } finally {
            setSetLoading(false)
        }
    }

    const confirm = () => {
        setSetLoading(true)
        api.get(
            "nomenclature/update-recalculated-prices/" + recalculateData,
            {}
        )
            .then(() => {
                showNotification({
                    type: "success",
                    message: "Сохранено",
                })
            })
            .catch(handleApiError)
            .finally(() => {
                setSetLoading(false)
                setOpenModal(false)
            })
    }

    const onCancel = () => {
        api.get(
            `nomenclature/delete-recalculated-prices/${recalculateData}`
        ).catch(() => {})
        setRecalculateData(null)
        setOpenModal(false)
        remove()
    }

    return (
        <Flex.Row fullWidth gap={10} justify="start">
            <Button
                disabled={!found?.entityList?.length}
                onClick={() => {
                    setOpenModal(true)
                }}
                type="primary"
            >
                Пересчитать цены
            </Button>
            <Button
                disabled={!query?.rules?.length}
                loading={isFetching}
                icon={<SyncOutlined />}
                onClick={() => refetch()}
            />
            <Flex.Row justify="center" align="center" styles={{ width: 200 }}>
                {!!found && !isFetching && (
                    <Typography.Text>
                        {paginator.totalCount
                            ? `Найдено: ${paginator.totalCount}`
                            : "Не найдено"}
                    </Typography.Text>
                )}
            </Flex.Row>
            <Modal
                width={"calc(100vw - 100px)"}
                style={{ maxWidth: 1200 }}
                open={modalOpened}
                title="Найденная номенклатура"
                onCancel={onCancel}
                okButtonProps={{
                    loading: saveLoading,
                    disabled: !!data && data.progress < 0,
                }}
                okText={!data ? "Рассчитать" : "Сохранить"}
                onOk={!data ? save : confirm}
            >
                {!data && (
                    <Flex.Col gap={10} relative fullWidth>
                        <Table
                            className="fw"
                            loading={isFetching}
                            dataSource={found?.entityList ?? []}
                            rowKey={(row) => row.id}
                            columns={[
                                {
                                    title: "Название",
                                    key: "id",
                                    dataIndex: "name",
                                    filterSearch: true,
                                    render(value, record) {
                                        return (
                                            <Link
                                                href={`/configuration/nomenclature/update-${record.id}`}
                                                target="_blank"
                                            >
                                                {value}
                                            </Link>
                                        )
                                    },
                                },
                            ]}
                            pagination={false}
                        />
                        <Pagination
                            pageSize={paginator.pageSize}
                            locale={{
                                page: "Страница",
                                next_page: "Следующая страница",
                                prev_page: "Предыдущая страница",
                                items_per_page: "на странице",
                            }}
                            total={paginator.totalCount}
                            current={paginator.currentPage}
                            onChange={(page, pageSize) => {
                                setPaginator((prev) => ({
                                    ...prev,
                                    pageSize,
                                    currentPage: page,
                                }))
                            }}
                        />
                    </Flex.Col>
                )}
                {data && (
                    <Flex.Col fullWidth gap={10}>
                        <Progress className="fw" percent={data.progress} />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <div>
                                {data.isReindexing && (
                                    <>
                                        <span style={{ color: "red" }}>
                                            Реиндекс:{" "}
                                        </span>
                                        пересчет может замедлиться.
                                    </>
                                )}
                            </div>

                            <div>
                                ~{Math.round(data.pricesPerSecond / 60)} цен в
                                секунду
                            </div>
                        </div>
                        <UTable
                            data={data.prices}
                            maxRowsVisible={30}
                            columns={[
                                {
                                    columnName: "Дата установки цены",
                                    render: (row) =>
                                        getDateString(row.updatedAt, true),
                                    width: 15,
                                },
                                {
                                    columnName: "Имя номенклатуры",
                                    render: (row) => (
                                        <Link
                                            href={`/configuration/nomenclature/update-${row.nomenclatureId}`}
                                            target="_blank"
                                        >
                                            {row.nomenclature}
                                        </Link>
                                    ),
                                },
                                {
                                    columnName: "Тип цены",
                                    render: (row) => row.typeOfPrice,
                                    width: 15,
                                },
                                {
                                    columnName: "Значение цены",
                                    render: (row) => row.price,
                                    width: 15,
                                },
                                {
                                    columnName: "Валюта",
                                    render: (row) => row.currency.name,
                                    width: 15,
                                },
                            ]}
                        />
                        {!!data?.data?.errorLog?.errors?.length && (
                            <Flex.Col fullWidth>
                                <Typography.Title level={5}>
                                    Ошибки
                                </Typography.Title>
                                <List
                                    className="fw"
                                    dataSource={data.data.errorLog.errors}
                                    renderItem={(row) => (
                                        <List.Item>{row}</List.Item>
                                    )}
                                ></List>
                            </Flex.Col>
                        )}
                    </Flex.Col>
                )}
            </Modal>
        </Flex.Row>
    )
}
