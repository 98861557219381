import { DownOutlined, UpOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Col, Row, Spin, Switch } from "antd"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import api from "helpers/api"
import { useMemo, useState } from "react"
import { AttributeLink, Uuid } from "types"
import { useAttributeValuesPricesStore } from "./AttributeValuesPrices.store"

type TableDataItem = {
    name: string
    id: Uuid
    type: "attribute" | "value"
    attributeId?: Uuid
    attributeName?: string
}

export default function AttriubetValuesTables() {
    const { activeModel, activeSeries, activeValues, toggleActiveValue } =
        useAttributeValuesPricesStore()

    const [activeRows, setActiveRows] = useState<Uuid[]>([])

    const { data, isFetching } = useQuery<AttributeLink[]>({
        queryKey: [activeModel, activeSeries],
        refetchOnWindowFocus: false,
        enabled: !!activeModel?.value && !!activeSeries?.value,
        queryFn: () =>
            api
                .getTyped<AttributeLink[]>(
                    "attribute/list-by-series-and-model",
                    {
                        series: activeSeries?.value,
                        model: activeModel?.value,
                    }
                )
                .then((data) =>
                    data.filter(
                        (el) => el.configurableAttributeValues?.length
                    )
                ),
        initialData: [],
    })

    const tableData: TableDataItem[] = useMemo(() => {
        return data.reduce((acc, next) => {
            return [
                ...acc,
                {
                    id: next.attribute.id,
                    name: next.attribute.name,
                    type: "attribute",
                },
                ...next.configurableAttributeValues.filter(el => !!next.attribute.valuesList?.[el]).map((el) => {
                    const value = next.attribute.valuesList?.[el];
                        return {
                            id: el,
                            name: value,
                            type: 'value',
                            attributeId: next.attribute.id,
                            attributeName: next.attribute.name,
                        } as TableDataItem
                }),
            ]
        }, [] as TableDataItem[])
    }, [data])

    if (isFetching)
        return (
            <Flex.Col fullWidth>
                <Spin />
            </Flex.Col>
        )
    return (
        <Row gutter={[10, 10]} className="fw">
            <Col span={24}>
                <UTable
                    search
                    searchCondition={(row, search) =>
                        row.name.toLowerCase().includes(search.toLowerCase())
                    }
                    data={tableData}
                    onRowClick={(row) => {
                        if (!row.attributeId)
                            return setActiveRows((prev) =>
                                prev.includes(row.id)
                                    ? prev.filter((el) => el !== row.id)
                                    : [...prev, row.id]
                            )
                        toggleActiveValue(row.id)
                    }}
                    isHidden={(row) =>
                        !!row.attributeId &&
                        !activeRows.includes(row.attributeId)
                    }
                    nested={(row) => row.type === "attribute"}
                    columns={[
                        {
                            columnName: "Свойство",
                            render: (row) =>
                                row.type === "attribute" ? row.name : null,
                            width: 20,
                        },
                        {
                            columnName: "Значение",
                            render: (row) =>
                                row.type === "attribute" ? null : row.name,
                            width: 40,
                        },
                        {
                            columnName: () => (
                                <Flex.Row
                                    fullHeight
                                    fullWidth
                                    justify="end"
                                    padding={[0, 15]}
                                    onClick={() =>
                                        setActiveRows((prev) =>
                                            prev.length
                                                ? []
                                                : tableData
                                                      .filter(
                                                          (el) =>
                                                              !el.attributeId
                                                      )
                                                      .map((el) => el.id)
                                        )
                                    }
                                >
                                    {activeRows.length ? (
                                        <UpOutlined />
                                    ) : (
                                        <DownOutlined />
                                    )}
                                </Flex.Row>
                            ),
                            render: (row) =>
                                row.type === "value" ? (
                                    <Switch
                                        style={{ maxWidth: 50 }}
                                        size="small"
                                        checked={activeValues.includes(row.id)}
                                        onClick={(_, event) => {
                                            event.preventDefault()
                                            event.stopPropagation()
                                            toggleActiveValue(row.id)
                                        }}
                                    />
                                ) : (
                                    <Flex.Row
                                        fullWidth
                                        fullHeight
                                        justify="end"
                                        padding={[0, 15]}
                                    >
                                        {!activeRows.includes(row.id) ? (
                                            <DownOutlined />
                                        ) : (
                                            <UpOutlined />
                                        )}
                                    </Flex.Row>
                                ),
                        },
                    ]}
                    // nested={(row) => row.type === "value"}
                />
            </Col>
        </Row>
    )
}
