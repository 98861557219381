import { RegionSelect } from "components/Selects/newSelects/RegionSelect"
import { SelectEntity } from "components/Selects/types"
import { useCallback } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SET_CURRENT_REGION } from "store/region/actions"
import { RootState } from "store/types"
import api from "../helpers/api"
import { clearUser } from "store/user/actions"
import { useHistory } from "react-router-dom"

const Header = () => {
    const region = useSelector((state: RootState) => state.region.region)
    const dispatch = useDispatch()
    const history = useHistory()

    const onSelectRegion = useCallback(
        (region: SelectEntity | null) => {
            dispatch(SET_CURRENT_REGION(region))
            api.setRegion(region?.value || null)
        },
        [dispatch]
    )

    const sidebarHandler = () => {
        let sidebar = document.querySelector(".sidebar")
        if (sidebar != null) sidebar.classList.toggle("collapsed")
    }

    const logoutHandler = () => {
        api.get("user/logout", {}).finally(() => {
            localStorage.clear()
            dispatch(clearUser())
            history.replace('/?logout')
        })
    }

    return (
        <header className="p-3 mb-3 border-bottom page-header">
            <div className="d-flex flex-wrap align-items-center justify-content-lg-start">
                <div className="sidebar-toggle" onClick={sidebarHandler}>
                    <i className="hamburger align-self-center"></i>
                </div>
                <a
                    href="/"
                    className="d-flex col-lg-auto me-lg-auto text-dark text-decoration-none"
                >
                    <svg
                        className="bi"
                        width="40"
                        height="32"
                        role="img"
                        aria-label="Bootstrap"
                    ></svg>
                </a>
                <RegionSelect
                    noLabel
                    activeRegion={region}
                    setActiveRegion={onSelectRegion}
                    containerStyles={{
                        minWidth: 200,
                    }}
                    size="large"
                />

                <div className="dropdown text-end authorization">
                    <a
                        href="javascript.void(0)"
                        className="d-block link-dark text-decoration-none dropdown-toggle"
                        id="dropdownUser1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <img
                            src="/img/user.png"
                            width="32"
                            height="32"
                            className="rounded-circle"
                            alt="User"
                        />
                    </a>
                    <ul
                        className="dropdown-menu text-small"
                        aria-labelledby="dropdownUser1"
                    >
                        {/* <li><a className="dropdown-item" href="#">Профиль</a></li>
						<li>
							<hr className="dropdown-divider" />
						</li> */}
                        <li>
                            <button
                                className="dropdown-item"
                                onClick={logoutHandler}
                            >
                                Выйти
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
}

export default Header
