export const listingsMap = new Map([
    ["good", "Товары"],
    ["catalog", "Разделы"],
    ["attribute", "Свойства"],
    ["series", "Серии"],
    ["model", "Модели"],
    ["nomenclature", "Номенклатура"],
    ["producer", "Поставщики"],
    ["items-group", "Номенклатурные группы"],
    ["okrb", "ОКРБ"],
    ["tnved", "ТНВЭД"],
    ["classification-according", "Соответствие классификаций"],
    ["tag-page", "Теговые страницы"],
    ["import-log", "Отчёты о запусках"],
    ["list", "Профили"],
    ["compatible-collection", "Коллекции"],
    ["delivery-time", "Сроки поставки"],
    ["price-margin-preset", "Типы наценок"],
])

export const recognizePage = (apiPath: string) => {
    if (listingsMap.has(apiPath)) {
        return listingsMap.get(apiPath)
    } else return "Unknown"
}
