import React from "react"
import toast from "react-hot-toast"
import api from "../../../helpers/api"

export interface IUploadedFile {
    id: number
    public_link: string
    original_name: string
}

export const ReadyPictureConfiguratorPictureUploader: React.FC<{
    onUploaded: (file: IUploadedFile) => void
    uploadedPicture?: IUploadedFile
    id: number
}> = ({ onUploaded, uploadedPicture, id }) => {
    const checkMimeType = (mimeType: string) =>
        ["image/png", "image/jpeg", "image/jpg"].includes(mimeType)

    const sendFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files!.length) {
            return
        }
        const file = event.target.files![0]
        const fileLimit: number = 20971520 // 20Mb

        if (
            file.size >= fileLimit ||
            file.size === 0 ||
            !checkMimeType(file.type)
        ) {
            event.target.value = ""
            return toast.error("Файл должен быть PNG и весить не более 20 МБ")
        }

        const formData = new FormData()
        formData.append("file", file)

        event.target.value = ""
        await toast.promise(
            api.post<any, IUploadedFile>(
                "file/upload/door-layer",
                {},
                formData
            ),
            {
                loading: "Загрузка...",
                success: ({ data }) => {
                    onUploaded(data)
                    return <b>Файл успешно загружен</b>
                },
                error: (response) => {
                    const error =
                        response ?? "Не удалось загрузить, неизвестная ошибка"
                    return <b>{String(error)}</b>
                },
            },
            { duration: 3500 }
        )
    }

    return (
        <div
            style={{
                gap: 10,
                display: "grid",
                gridTemplateColumns: "100px 1fr",
            }}
        >
            <button
                type="button"
                className="btn btn-outline-secondary btn-sm"
                style={{ maxHeight: 34 }}
                onClick={() =>
                    (
                        document.querySelector(
                            `#configurator-file-uploader-input-${id}`
                        ) as HTMLElement
                    )?.click()
                }
            >
                Загрузить
            </button>
            <div style={{ width: "100%", overflow: "hidden" }}>
                <p>
                    {uploadedPicture?.original_name ?? "Изображение не выбрано"}
                </p>
            </div>

            <input
                type="file"
                onChange={(e) => sendFile(e)}
                style={{ display: "none" }}
                id={"configurator-file-uploader-input-" + id}
            />
        </div>
    )
}
