import { SyncOutlined } from "@ant-design/icons"
import { useQuery } from "@tanstack/react-query"
import { Button, Modal, Table, Typography } from "antd"
import Link from "antd/es/typography/Link"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { useCallback, useEffect, useState } from "react"
import { RuleGroupType } from "react-querybuilder"
import { NomenclatureItem } from "types/api"

type Paginator = {
    allPages: number
    currentPage: number
    pageSize: number
    totalCount: number
}

type GetNomenclatureRequest = {
    page: number
    countOnPage: number
    payload: {
        query?: RuleGroupType<any>
    }
}

export type NomenclatureResponse = {
    entityList: NomenclatureItem[]
    paginator: Paginator
}

const defaultPaginator = {
    allPages: 0,
    currentPage: 1,
    pageSize: 20,
    totalCount: 0,
}

export default function QueryBuilderResult({
    dataUrl,
    query,
    onFind,
    enabled,
}: {
    dataUrl: string
    query?: RuleGroupType<any>
    onFind?: (totalFound: number) => void
    enabled?: (v?: RuleGroupType<any>) => boolean
}) {
    const [openModal, setOpenModal] = useState(false)
    const [paginator, setPaginator] = useState<Paginator>(defaultPaginator)
    const [found, setFound] = useState<NomenclatureResponse | null>(null);
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback((paginator: Paginator) => {
        setLoading(true)
        api
        .post<GetNomenclatureRequest, NomenclatureResponse>(
            dataUrl,
            {},
            {
                page: paginator.currentPage,
                countOnPage: paginator.pageSize,
                payload: {
                    query: query,
                },
            }
        )
        .then(({ data }) => {
            setPaginator(data.paginator)
            onFind && onFind(data.paginator.totalCount)
            setFound(data)
        }).finally(() => {
            setLoading(false);
        })
    }, [
        query,
    ])

    return (
        <>
            <Button
                disabled={!found?.entityList?.length}
                onClick={() => {
                    setOpenModal(true)
                }}
            >
                Показать номенклатуру
            </Button>
            <Button
                disabled={!query?.rules?.length && query?.rules.every(rule => !!rule.value)}
                loading={loading}
                icon={<SyncOutlined />}
                onClick={() => fetchData(paginator)}
            />
            <Flex.Row justify="center" align="center" styles={{ width: 200 }}>
                {!!found && !loading && (
                    <Typography.Text>
                        {paginator.totalCount
                            ? `Найдено: ${paginator.totalCount}`
                            : "Не найдено"}
                    </Typography.Text>
                )}
            </Flex.Row>
            <Modal
                open={openModal}
                onCancel={() => setOpenModal(false)}
                title="Найденная номенклатура"
                cancelButtonProps={{ hidden: true }}
                okText="Закрыть"
                onOk={() => setOpenModal(false)}
            >
                <Table
                    loading={loading}
                    dataSource={found?.entityList ?? []}
                    columns={[
                        {
                            title: "Название",
                            key: "id",
                            dataIndex: "name",
                            render(value, record) {
                                return (
                                    <Link
                                        href={`/configuration/nomenclature/update-${record.id}`}
                                        target="_blank"
                                    >
                                        {value}
                                    </Link>
                                )
                            },
                        },
                    ]}
                    pagination={{
                        pageSize: paginator.pageSize,
                        locale: {
                            page: "Страница",
                            next_page: "Следующая страница",
                            prev_page: "Предыдущая страница",
                            items_per_page: "на странице",
                        },
                        total: paginator.totalCount,
                        current: paginator.currentPage,
                        onChange: (page, pageSize) => {
                            setPaginator((prev) => ({
                                ...prev,
                                pageSize,
                                currentPage: page,
                            }))
                            fetchData({...paginator, pageSize, currentPage: page });
                        },
                    }}
                />
            </Modal>
        </>
    )
}
