import { useQuery } from "@tanstack/react-query"
import { Form, Spin } from "antd"
import clsx from "clsx"
import { Layout } from "components/layouts"
import { Select, selectProps } from "components/Selects/newSelects"
import { GroupSelect } from "components/Selects/newSelects/GroupSelect"
import { Rounding } from "components/Selects/newSelects/Rounding"
import { SelectEntity } from "components/Selects/types"
import { Flex } from "components/UI/Flex"
import api from "helpers/api"
import { getFieldValue } from "helpers/getFieldValue"
import { useDetailData } from "hooks/useDetailData"
import { useRegion } from "hooks/useRegion"
import { useState } from "react"
import { Uuid } from "types"
import { ListingResponse, Producer } from "types/api"
import ProducerDetailCombinationOrder from "./ProducerDetailCombinationOrder"
import ProducerPriceSet from "./ProducerPriceSet"

export default function ProducerDetail() {
    const { pageTitle, id } = useDetailData()
    const { activeRegion } = useRegion()

    const [seriesId, setSeriesId] = useState<Uuid>("")
    const [seriesName, setSeriesName] = useState<string>("")
    const [rounding, setRounding] = useState<Rounding | null>(null)
    const [activGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [isValidItemsGroup, setIsValidItemsGroup] = useState(false)
    const [activeCurrency, setActiveCurrency] = useState<SelectEntity | null>(
        null
    )
    const [activeDefaultCurrency, setActiveDefaultCurrency] =
        useState<SelectEntity | null>(null)

    useQuery({
        queryKey: [id],
        queryFn: () => api.getTyped<Producer>(`reference/producer/${id}`),
        onSuccess: (data) => {
            setSeriesId(data.id)
            setSeriesName(data.name)
        },
    })

    const { isFetching } = useQuery({
        queryKey: [activGroup, id],
        enabled: !!id && !!activGroup,
        queryFn: () =>
            api
                .getTyped<ListingResponse>("listing/producer", {
                    itemsGroup: activGroup!.value,
                })
                .then((response) => {
                    const isProducerInResponse = response.entities.find(
                        (el) => {
                            const responseId = getFieldValue<Uuid>(
                                el.fields,
                                "id"
                            )
                            return id === responseId
                        }
                    )
                    setIsValidItemsGroup(!!isProducerInResponse)
                    return response
                }),
    })

    return (
        <Layout.Detail pageTitle={pageTitle}>
            <Flex.Col gap={10} fullWidth>
                <Form
                    className="fw"
                    labelCol={{ xl: 6, md: 24 }}
                    labelAlign="left"
                    prefixCls="shyki"
                >
                    <Form.Item label="ID" className="fw">
                        <input
                            className="fw form-control"
                            disabled
                            value={seriesId}
                        />
                    </Form.Item>

                    <Form.Item label="Название" className="fw">
                        <input
                            className="fw form-control"
                            disabled
                            value={seriesName}
                        />
                    </Form.Item>

                    <Flex.Col gap={20} fullWidth>
                        <Select.Currency
                            containerClassName={clsx({
                                "--visible": activeRegion,
                                "--hidden": !activeRegion,
                            })}
                            getValueProps={{
                                url: `v1/economics/producer/default-currency`,
                                getParams: {
                                    producer: id,
                                    region: activeRegion?.value,
                                },
                                getPutData: (value) => ({
                                    producer: id,
                                    region: activeRegion?.value,
                                    currency: value.value,
                                }),
                                enabled: !!activeRegion && !!id,
                            }}
                            activeCurrency={activeDefaultCurrency}
                            setActiveCurrency={setActiveDefaultCurrency}
                            label="Валюта по умолчанию"
                            {...selectProps.detail}
                        />

                        <Select.Rounding
                            activeRounding={rounding}
                            setActiveRounding={setRounding}
                            getValueProps={{
                                url: "v1/economics/producer/decimals",
                                getParams: { producer: id },
                                getPutData(value) {
                                    return {
                                        producer: id,
                                        decimals: value.value,
                                    }
                                },
                            }}
                            {...selectProps.detail}
                            label="Округление"
                        />

                        <GroupSelect
                            containerClassName={clsx({
                                "--visible":
                                    activeRegion && activeDefaultCurrency,
                                "--hidden":
                                    !activeRegion || !activeDefaultCurrency,
                            })}
                            activeGroup={activGroup}
                            setActiveGroup={setActiveGroup}
                            {...selectProps.detail}
                        />

                        <Select.Currency
                            containerClassName={clsx({
                                "--visible": activeRegion && activGroup,
                                "--hidden": !activeRegion || !activGroup,
                            })}
                            getValueProps={{
                                url: `v1/economics/producer/group-currency`,
                                getParams: {
                                    producer: id,
                                    region: activeRegion?.value,
                                    group: activGroup?.value,
                                },
                                getPutData: (value) => ({
                                    producer: id,
                                    region: activeRegion?.value,
                                    group: activGroup?.value,
                                    currency: value.value,
                                }),
                                enabled: !!id && !!activeRegion && !!activGroup,
                            }}
                            activeCurrency={activeCurrency}
                            setActiveCurrency={setActiveCurrency}
                            label="Валюта в группе"
                            {...selectProps.detail}
                        />

                        <div
                            className={clsx({
                                fw: true,
                                "--visible": activGroup,
                                "--hidden": !activGroup || isFetching,
                            })}
                        >
                            <ProducerPriceSet
                                groupId={activGroup?.value}
                                regionId={activeRegion?.value}
                            />
                        </div>
                    </Flex.Col>
                </Form>

                <Flex.Col fullWidth align="center">
                    {isFetching && <Spin />}

                    {!!activGroup && !!activeRegion && !isFetching && (
                        <ProducerDetailCombinationOrder
                            activeGroup={activGroup}
                            activeRegion={activeRegion}
                        />
                    )}
                </Flex.Col>
            </Flex.Col>
        </Layout.Detail>
    )
}
