import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { collapseTree } from "../../partials/Tree/collapseTree"
import { getCategory } from "../../store/category/actions"
import {
    RESET_GOODS_ATTRIBUTES,
    SET_CURRENT_VALUES,
} from "../../store/fields/actions"
import { ParentSectionProps } from "../../types/interfaces"

import { useHistory } from "react-router-dom"
import { Uuid } from "types"
import api from "../../helpers/api"
import "../../partials/Tree/tree.css"

export const ParentSection: React.FC<ParentSectionProps> = (props) => {
    const {
        id,
        categoryGoodsID,
        isGoods,
        currentParentID,
        fetchFilteredGoods,
        pageSlug,
    } = props

    const [disabledCategories, setDisabledCategories] = React.useState<any>([])

    const currentValues = useSelector(
        (state: { [key: string]: any }) => state.fields.currentValues["main"]
    )
    const categories = useSelector(
        (state: { [key: string]: any }) => state.category
    )

    const dispatch = useDispatch()

    const history: { [key: string]: any } = useHistory()
    const sortedSettings =
        useSelector(
            (state: { [key: string]: any }) =>
                state.savedUserSettings?.sortedColumns
        ) || []
    const columnName = sortedSettings.columnName
    const sortMethod = sortedSettings.sortMethod
    const paginationSettings =
        useSelector(
            (state: { [key: string]: any }) => state.listing?.pagination
        ) || {}

    const iconClosed = (
        <span>
            <svg
                className="tree-icon-closed"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
            >
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
            </svg>
        </span>
    )

    const iconOpened = (
        <svg
            className="tree-icon-opened"
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
        >
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
        </svg>
    )

    const iconEmpty = <span className="tree-icon-empty"></span>

    useEffect(() => {
        const getData = async () => {
            const { data } = await api.get("imported-categories/disabled")
            setDisabledCategories(data)
        }
        dispatch(getCategory(`catalog`))

        if (pageSlug === "imported-category") {
            getData()
        }

        dispatch(SET_CURRENT_VALUES({ parent: currentParentID }))
        if (currentParentID && categories) {
            setTimeout(() => {}, 500)
        }
    }, [dispatch, currentParentID])

    const transformToTree = (items: any, id = null, link = "parent") =>
        items
            .filter((item: any) => item[link] === id)
            .map((item: any) => ({
                ...item,
                children: transformToTree(items, item.id),
            }))

    const editParentToNull = (items: []) => {
        return items.map((item: any) => {
            if (item.parent === 1) {
                item.parent = null
            } else {
                return item
            }
            return item
        })
    }

    const clickHandler = (event: { target: { id: Uuid } }) => {
        // dispatch(RESET_COMPARE_TYPE({}));
        // dispatch(RESET_CURRENT_VALUES());
        // dispatch(SAVE_FOR_TEMP({ filter: '', attributes: '' }));
        dispatch(RESET_GOODS_ATTRIBUTES())
        if (isGoods) {
            dispatch(
                SET_CURRENT_VALUES({ category: parseInt(event.target.id) })
            )
        } else {
            dispatch(SET_CURRENT_VALUES({ parent: parseInt(event.target.id) }))
        }
        if (pageSlug === "good" || pageSlug === "imported-category") {
            fetchFilteredGoods(parseInt(event.target.id))
        } else {
            if (!window.confirm("Вы уверены что хотите сменить раздел?")) return
        }

        let query = `&count_on_page=${paginationSettings.pageSize}&method=${sortMethod}&page=1&sort=${columnName}`

        if (parseInt(event.target.id)) {
            history.push(`?cat=${event.target.id}` + query)
        }
        if (!event.target.id || parseInt(event.target.id) === 1) {
            history.push("?" + query)
        }
    }

    const resultTree = transformToTree(editParentToNull(categories))

    const parsingTree = (tree: []) => {
        return (
            <ul className="tree">
                {tree.map((item: any) => {
                    if (item.children.length) {
                        return (
                            <li key={item.id}>
                                <div className="tree-node-name tree-node-single">
                                    <div
                                        className="tree-toggle-btn"
                                        onClick={(event: any) => {
                                            collapseTree(event)
                                        }}
                                    >
                                        {item.children.length
                                            ? iconOpened
                                            : iconEmpty}
                                    </div>
                                    <button
                                        title={
                                            disabledCategories.length !== 0 &&
                                            disabledCategories.includes(item.id)
                                                ? "Категория недоступна"
                                                : ""
                                        }
                                        style={{
                                            opacity:
                                                disabledCategories.length !==
                                                    0 &&
                                                disabledCategories.includes(
                                                    item.id
                                                )
                                                    ? 0.7
                                                    : 1,
                                        }}
                                        disabled={
                                            (item.id === +id ? true : false) ||
                                            (disabledCategories.length !== 0 &&
                                                disabledCategories.includes(
                                                    item.id
                                                ))
                                        }
                                        className={
                                            isGoods
                                                ? item.id === categoryGoodsID
                                                    ? "active"
                                                    : ""
                                                : item.id ===
                                                  (currentValues?.parent ||
                                                      currentParentID ||
                                                      1)
                                                ? "active"
                                                : ""
                                        }
                                        id={item.id}
                                        onClick={(event: any) => {
                                            clickHandler(event)
                                        }}
                                    >
                                        {item.title}
                                    </button>
                                </div>
                                {parsingTree(item.children)}
                            </li>
                        )
                    }
                    return (
                        <li key={item.id}>
                            <div className="tree-node-name tree-node-single">
                                <div
                                    className="tree-toggle-btn"
                                    onClick={(event: any) => {
                                        collapseTree(event)
                                    }}
                                >
                                    {item.children.length
                                        ? iconClosed
                                        : iconEmpty}
                                </div>
                                <button
                                    title={
                                        disabledCategories.length !== 0 &&
                                        disabledCategories.includes(item.id)
                                            ? "Категория недоступна"
                                            : ""
                                    }
                                    style={{
                                        opacity:
                                            disabledCategories.length !== 0 &&
                                            disabledCategories.includes(item.id)
                                                ? 0.7
                                                : 1,
                                    }}
                                    disabled={
                                        (item.id === +id ? true : false) ||
                                        (disabledCategories.length !== 0 &&
                                            disabledCategories.includes(
                                                item.id
                                            ))
                                    }
                                    className={
                                        isGoods
                                            ? item.id === categoryGoodsID ||
                                              (pageSlug === "good" &&
                                                  item.id === 1 &&
                                                  (currentValues?.category ===
                                                      undefined ||
                                                      currentValues?.category ===
                                                          null))
                                                ? "active"
                                                : ""
                                            : item.id ===
                                              (currentValues?.parent ||
                                                  currentParentID ||
                                                  1)
                                            ? "active"
                                            : ""
                                    }
                                    id={item.id}
                                    onClick={(event: any) => {
                                        clickHandler(event)
                                    }}
                                >
                                    {item.title}
                                </button>
                            </div>
                        </li>
                    )
                })}
            </ul>
        )
    }

    return <div className="tree-wrapper">{parsingTree(resultTree)}</div>
}
