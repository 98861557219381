import { Attribute } from "types/api"
import { Col, Row, Input } from 'antd';
import { useState, KeyboardEvent } from "react";
import { parseDimensions } from "./parseDimensions";
import { isDiemnsionAttribute } from "helpers/isDimensionAttribute";

interface Props {
    attribute?: Attribute
    resolveDimensions: (dimensions: number[]) => void
}


export default function AttributValuesDimensionsParse ({
    attribute,
    resolveDimensions
} : Props) {

    const [value, setValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        const processedValue = value.replace(/[^\d-,*]/g, '');
        event.target.value = processedValue;
        setValue(processedValue);
      };

    const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            parseDimensions(value, resolveDimensions)
        }
    };

    const isShown = !!attribute && isDiemnsionAttribute(attribute)

    if (!isShown) return null

    return (
        <Row gutter={[2, 2]} style={{ marginBottom: 10 }} align="middle">
            <Col xs={4}>
            <h6 className="no-margin">
                Размер
            </h6>
            </Col>
            <Col xs={20}>
                <Input onKeyDown={onKeyDown} placeholder="Код" value={value} onChange={handleChange}/>
            </Col> 
        </Row>
    )
}