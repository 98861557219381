import { Collapse, Typography } from "antd"
import { Flex } from "components/UI/Flex"
import dayjs from "dayjs"
import { getFieldsValues } from "helpers/getFieldValue"
import { useState } from "react"
import { Uuid } from "types"
import { ListingResponse } from "types/api"
import RequesitesLogItem from "./Requesites.LogItem"

type LogType = {
    id: Uuid
    total: number
    successful: number
    skipped: number
    createdAt: string
    updatedAt: string
    progress: number
}

type RequesitesPropsLogs = {
    logs: ListingResponse["entities"]
    loading: boolean
}

export default function RequesitesLogs({ logs, loading }: RequesitesPropsLogs) {
    const parsedLogs = logs
        ? logs
              .map((el) =>
                  getFieldsValues<LogType>(
                      el.fields,
                      "id",
                      "total",
                      "createdAt",
                      "updatedAt",
                      "skipped",
                      "successful"
                  )
              )
              .sort((a, b) =>
                  dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1
              )
        : []

    const [openedCollapses, setOpenedCollapses] = useState<Uuid[]>([])

    if (!logs.length) return null

    return (
        <Flex.Col fullWidth gap={10}>
            <Typography.Title level={5}>
                История изменения реквизитов
            </Typography.Title>
            <Collapse
                className="fw"
                onChange={(key) => {
                    if (Array.isArray(key)) {
                        setOpenedCollapses(key)
                    } else {
                        setOpenedCollapses([...key])
                    }
                }}
            >
                {parsedLogs.map((log) => (
                    <Collapse.Panel
                        key={log.id}
                        header={
                            <Flex.Row gap={20} justify="start" fullWidth>
                                <Typography.Text>
                                    Дата:
                                    <span
                                        className="bold"
                                        style={{ marginLeft: 10 }}
                                    >
                                        {dayjs(log.updatedAt).format(
                                            "DD-MM-YYYY HH:mm"
                                        )}
                                    </span>
                                </Typography.Text>
                                <Typography.Text>
                                    Изменено:
                                    <span
                                        className="bold"
                                        style={{ marginLeft: 10 }}
                                    >
                                        {log.successful}
                                    </span>
                                </Typography.Text>

                                <Typography.Text>
                                    Пропущено:
                                    <span
                                        className="bold"
                                        style={{ marginLeft: 10 }}
                                    >
                                        {log.skipped ?? 0}
                                    </span>
                                </Typography.Text>
                            </Flex.Row>
                        }
                    >
                        {
                            <RequesitesLogItem
                                logId={openedCollapses.find(
                                    (el) => el === log.id
                                )}
                            />
                        }
                        {/* <UTable
                            data={el.log.nomenclatures}
                            columns={[
                                {
                                    columnName: "Название",
                                    render: (row) => row.name,
                                    width: 32,
                                },
                                {
                                    columnName: "Ном. группа",
                                    render: (row) => row.itemsGroup,
                                    width: 17,
                                },
                                {
                                    columnName: "Поставщик",
                                    render: (row) => row.producer,
                                    width: 17,
                                },
                                {
                                    render: (row) => row.series,
                                    columnName: "Серия",
                                    width: 17,
                                },
                                {
                                    render: (row) => row.model,
                                    columnName: "Модель",
                                    width: 17,
                                },
                            ]}
                        /> */}
                    </Collapse.Panel>
                ))}
            </Collapse>
        </Flex.Col>
    )
}
