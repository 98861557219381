import api from "helpers/api"
import { AttributeListItem } from "types"
import { Field } from "types/api"

enum Attribute {
    name = "nameAttributes",
    accounting = "accountingNameAttributes",
}
interface IValue {
    value: string
    label: string
    sort: number
}

export const getTextAboutAvailabilityNaming = ({
    availabilityName,
    availabilityAccountingName,
}: {
    availabilityName: boolean
    availabilityAccountingName: boolean
}) =>
    !availabilityName || !availabilityAccountingName
        ? `Нет настроек: ${!!availabilityName ? "" : "пользовательское"}${
              !availabilityName && !availabilityAccountingName ? "," : ""
          } ${!!availabilityAccountingName ? "" : "бухгалтерское"}`
        : ""

const getValueByNameFromField = (fields: Field[], name: string) =>
    fields.find((field) => field.name === name)?.value

export const getFieldsForViewData = (fields: Field[]) => {
    return {
        label: getValueByNameFromField(fields, "name"),
        availabilityName: getValueByNameFromField(fields, "availabilityName"),
        availabilityAccountingName: getValueByNameFromField(
            fields,
            "availabilityAccountingName"
        ),
    }
}

type Type = "accounting" | "userAccount"

export const getCurrentAttributes = (attributes: AttributeListItem[] = []) => {
    const getCurrentSort = (item: any, type: Type) =>
        type === "accounting" ? item.sortAccountingNaming : item.sortNaming

    const getCurrentFor = (item: any, type: Type) =>
        type === "accounting" ? item.forAccountingNaming : item.forNaming

    const processAttributes = (type: Type) =>
        attributes
            .filter(
                (el) =>
                    el.id && getCurrentFor(el, type) && getCurrentSort(el, type)
            )
            .sort((a, b) => getCurrentSort(a, type) - getCurrentSort(b, type))
            .map((v) => ({
                value: v.id,
                label: v.attribute.name,
                sort: getCurrentSort(v, type),
            }))

    return {
        options: attributes
            .filter((el) => el.id)
            .map(({ attribute, id }) => ({
                value: id,
                label: attribute.name,
                sort: 0,
            })),
        currentNameAttributes: processAttributes("userAccount"),
        currentAccountingAttributes: processAttributes("accounting"),
    }
}

export const changeAttributes = ({
    toAttributes,
    fromAttributes,
    type,
    attributes,
}: {
    toAttributes: keyof typeof Attribute
    fromAttributes: keyof typeof Attribute
    type: "series" | "model"
    attributes?: Record<Attribute, IValue[]>
}) =>
    api.post(
        `v1/${type}/links/${
            toAttributes === "name" ? "naming" : "accounting-naming"
        }`,
        {},
        attributes?.[Attribute[fromAttributes]].reduce((acc, next, i) => {
            return {
                ...acc,
                [next.value]: i + 1,
            }
        }, [])
    )

export const deleteAllAttributes = async ({
    type,
    attributes,
}: {
    type: "series" | "model"
    attributes?: Record<Attribute, IValue[]>
}) => {
    await api.post(
        `v1/${type}/links/naming`,
        {},
        attributes?.nameAttributes.reduce(
            (acc, next, i) => ({
                ...acc,
                [next.value]: null,
            }),
            []
        )
    )
    await api.post(
        `v1/${type}/links/accounting-naming`,
        {},
        attributes?.accountingNameAttributes.reduce(
            (acc, next, i) => ({
                ...acc,
                [next.value]: null,
            }),
            []
        )
    )
}
