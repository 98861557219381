import { useMutation, useQuery } from "@tanstack/react-query";
import { Typography } from "antd";
import { AsyncSelect } from "components/UI";
import { usePageData } from "hooks/usePageData";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Uuid } from "types";
import { UnpublishValuesResponse } from "types/api";
import ContentForm from "../../components/ContentForm";
import { SaveMenu } from "../../components/UI/SaveMenu";
import api from "../../helpers/api";
import { isEmptyObject } from "../../helpers/isEmptyObject";
import {
  getCurrentValues,
  getFieldsFromServer,
  INIT_STATE_GROUP,
} from "../../store/fields/actions";
import { ProducerAttributesChooser } from "./ProducerAttributesChooser";
import { ProducerAttributesValuesChooser } from "./ProducerAttributesValuesChooser";

export const ProducerDetails: React.FC = () => {
  const fields: object[] = useSelector(
    (state: { [key: string]: any }) => state.fields.initFields
  );
  const currentValues = useSelector(
    (state: { [key: string]: any }) => state.fields.currentValues?.["main"]
  );
  const attributeName: string = useSelector(
    (state: { [key: string]: any }) => state.fields.currentValues["main"]?.name
  );

  const { slug } = usePageData();

  const dispatch = useDispatch();
  const routerParams: { [key: string]: string } = useParams();

  const title: string = routerParams.id ? "Редактирование" : "Создание";

  const onModifySendData = React.useCallback((data: any) => {
    for (const code in data) {
      // * Clear ID
      if (code === "id") {
        delete data[code];
      }

      if (typeof data[code] === "object" && data[code]?.id) {
        data[code] = data[code].id;
      }
    }
  }, []);

  useEffect(() => {
    document.title = title;
    dispatch(INIT_STATE_GROUP({ main: {} }));

    dispatch(getFieldsFromServer(`reference/${slug}/fields`));
    dispatch(getCurrentValues(`reference/${slug}`, routerParams.id, "main"));
  }, [routerParams.id]);

  const [selectedAttribute, setSelectedAttribute] = useState<Uuid | undefined>(
    undefined
  );

  const [activeGroup, setActiveGroup] = useState<
    { value: Uuid; label: string } | undefined
  >(undefined);

  const {
    data: currentAttribute,
    isLoading: currentAttributeLoading,
    isFetching: currentAttributeFetching,
  } = useQuery<{
    valuesList: Record<Uuid, string>;
  }>({
    initialData: { valuesList: {} },
    queryKey: ["attribute", selectedAttribute],
    enabled: !!selectedAttribute,
    queryFn: () => api.getTyped(`attribute/${selectedAttribute}`),
  });

  const {
    data: altNamesDefinitions,
    isLoading: altNamesDefinitionsLoading,
    refetch: refetchAltNames,
  } = useQuery<Record<Uuid, string>>({
    queryKey: ["getAltNames", activeGroup, selectedAttribute, currentAttribute],
    enabled: !!activeGroup && !!selectedAttribute,
    initialData: {},
    queryFn: () =>
      api.getTyped<Record<Uuid, string>>(
        "attribute/group_producer_attributes_list_alt_names",
        {
          group: activeGroup?.value,
          producer: routerParams.id,
          attribute: selectedAttribute,
        }
      ),
  });

  const {
    data: accountingNames,
    isLoading: accountingNamesLoading,
    isFetching: accountingNamesFetching,
  } = useQuery<Record<Uuid, string>>({
    queryKey: [
      "getAccountingNames",
      activeGroup,
      selectedAttribute,
      currentAttribute,
      routerParams,
    ],
    enabled: !!activeGroup && !!selectedAttribute && !!routerParams.id,
    initialData: {},
    queryFn: () =>
      api.getTyped<Record<Uuid, string>>(
        `attribute/group_producer_attributes_list_alt_accounting_names?group=${
          activeGroup!.value
        }&producer=${routerParams.id}&attribute=${selectedAttribute}`
      ),
  });

  useEffect(() => {
    setSelectedAttribute(undefined);
  }, [activeGroup]);

  const {
    isLoading: linkedAttributesDetailsLoading,
    data: linkedAttributesDetails = [],
  } = useQuery<any[]>({
    queryKey: [
      "attribute/group_producer_attributes_linking_details",
      activeGroup?.value,
      routerParams.id,
    ],
    enabled: !!activeGroup,
    queryFn: () =>
      api.getTyped("attribute/group_producer_attributes_linking_details", {
        group: activeGroup?.value,
        producer: routerParams.id,
      }),
  });

  const [unpublishLoading, setUnpublishLoading] = useState<string>();

  const {
    data: unpublishedValuesResponse = [],
    refetch: refetchUnpublishedValues,
    isLoading: refetchUnpublishedValuesLoading,
  } = useQuery<UnpublishValuesResponse>({
    enabled: !!activeGroup && !!routerParams.id && !!selectedAttribute,
    queryKey: [selectedAttribute],
    keepPreviousData: true,
    retryOnMount: false,
    queryFn: () => {
      return api.getTyped(
        "attribute/group_producer_attributes_unpublished_by_attribute",
        {
          group: activeGroup?.value,
          producer: routerParams.id,
          attribute: selectedAttribute,
        }
      );
    },
    onSettled: () => {
      setUnpublishLoading(undefined);
    },
  });

  const unpublishedValues: Uuid[] = useMemo(() => {
    return unpublishedValuesResponse.reduce((acc, next) => {
      return [...acc, ...Object.keys(next.attributeValue)];
    }, [] as Uuid[]);
  }, [unpublishedValuesResponse]);

  const { mutate: unpublishAttribute } = useMutation({
    mutationFn: (value: Uuid) => {
      const deleteMethod = unpublishedValues.includes(value);
      const method = deleteMethod ? "published" : "unpublished";
      return api
        .post(
          `attribute/group_producer_attributes_${method}`,
          {},
          {
            producer: routerParams.id!,
            group: activeGroup?.value,
            attribute: selectedAttribute,
            attributeValues: deleteMethod
              ? [value]
              : [...unpublishedValues, value],
          }
        )
        .then((resposne) => resposne.data);
    },
    onSettled: () => {
      refetchUnpublishedValues();
    },
  });

  const { data: loadedActiveAttributeValuesList } = useQuery({
    initialData: {},
    enabled: !!selectedAttribute,
    queryKey: [
      "active-attribute-producer-values",
      selectedAttribute,
      routerParams.id,
    ],
    queryFn: () => {
      return api.getTyped(
        `v1/producer/${routerParams.id}/attribute/${selectedAttribute}/values`
      );
    },
  });

  const activeAttributeValuesList = useMemo(() => {
    return (
      Object.keys(loadedActiveAttributeValuesList).map((valueId) => {
        const alt = Object.keys(altNamesDefinitions ?? {}).find(
          (name) => name === valueId
        );
        const accounting = Object.keys(accountingNames ?? {}).find(
          (name) => name === valueId
        );
        return {
          id: valueId,
          alt: alt ? altNamesDefinitions[alt] : undefined,
          accounting: accounting ? accountingNames[accounting] : undefined,
          label: currentAttribute.valuesList[valueId] ?? "",
        };
      }) ?? []
    );
  }, [
    loadedActiveAttributeValuesList,
    altNamesDefinitions,
    accountingNames,
    currentAttribute,
  ]);

  return (
    <>
      <h1 className="h3 mb-3">
        {title}
        {routerParams.id &&
          ` "${
            (slug === "uploaded-attribute" && routerParams.id) ||
            attributeName ||
            "..."
          }"`}
      </h1>
      <SaveMenu url={`reference/${slug}`} onModify={onModifySendData} />
      <div className="control">
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              className="nav-link active"
              id="edit-settings-tab"
              data-bs-toggle="tab"
              data-bs-target="#edit-settings"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Настройка
            </button>
          </div>
        </nav>

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="edit-settings"
            role="tabpanel"
            aria-labelledby="edit-settings-tab"
          >
            <div className="control-view">
              <div className="control-view__main">
                <div className="control-in">
                  {routerParams.id && (
                    <div className="row mb-3">
                      <label className="col-xl-3 col-form-label">
                        Выбор группы
                      </label>
                      <div className="col-xl-9">
                        <AsyncSelect
                          placeholder="Выберите группу"
                          value={activeGroup}
                          onChange={(v) => setActiveGroup(v || undefined)}
                          className="mb-2"
                          dataUrl="items-group"
                        />
                      </div>
                    </div>
                  )}

                  <h5>Основное</h5>
                  {isEmptyObject(currentValues) ? (
                    <div>Загрузка...</div>
                  ) : (
                    <div>
                      <ContentForm
                        isCreate={!routerParams.id}
                        group={"main"}
                        fields={fields}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="control-view__info"></div>

              {routerParams.id && (
                <div className="control-view__linking">
                  <div className="row mt-3">
                    <div className="col-12">
                      <Typography.Title level={4}>
                        Доступность свойств и значений к текущему поставщику
                      </Typography.Title>
                    </div>
                    <div className="col-12 col-xl-6">
                      {activeGroup ? (
                        <ProducerAttributesChooser
                          rows={linkedAttributesDetails.map((v) => ({
                            id: v.attributeId,
                            label: v.attribute.name,
                            sort: linkedAttributesDetails.find(
                              (detail) => detail.attributeId === v.attributeId
                            )?.sortChoosing,
                          }))}
                          onChangeActiveRow={(n) => {
                            setSelectedAttribute(n);
                          }}
                          activeRow={selectedAttribute}
                          onChangeSort={(id, sort) => {
                            api.post(
                              "attribute/group_producer_attributes_setup_choosing_sort",
                              {},
                              {
                                group: activeGroup?.value,
                                producer: routerParams.id,
                                attribute: id,
                                sort,
                              }
                            );
                          }}
                          loading={linkedAttributesDetailsLoading}
                        />
                      ) : (
                        "Выберите номенклатурную группу"
                      )}
                    </div>
                    <div className="col-12 col-xl-6">
                      {activeGroup && selectedAttribute ? (
                        <ProducerAttributesValuesChooser
                          unpublishedValues={unpublishedValues}
                          unpublishAttribute={unpublishAttribute}
                          unpublishLoading={unpublishLoading}
                          setUnpublishLoading={setUnpublishLoading}
                          rows={activeAttributeValuesList}
                          onChangeAltName={(row) => {
                            api.post(
                              "attribute/group_producer_attributes_setup_alt_names",
                              {},
                              {
                                group: activeGroup?.value,
                                producer: routerParams.id,
                                attribute: selectedAttribute,
                                altNames: row,
                              }
                            );
                          }}
                          onChangeAccountingName={(row) => {
                            api.post(
                              "attribute/group_producer_attributes_setup_alt_accounting_names",
                              {},
                              {
                                group: activeGroup?.value,
                                producer: routerParams.id,
                                attribute: selectedAttribute,
                                altAccountingNames: row,
                              }
                            );
                          }}
                          loading={
                            altNamesDefinitionsLoading ||
                            linkedAttributesDetailsLoading ||
                            refetchUnpublishedValuesLoading ||
                            currentAttributeLoading ||
                            currentAttributeFetching ||
                            accountingNamesLoading ||
                            accountingNamesFetching
                          }
                        />
                      ) : (
                        "Выберите номенклатурную группу и свойство"
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <SaveMenu url={`reference/${slug}`} onModify={onModifySendData} />
    </>
  );
};
