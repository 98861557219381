import axios, { AxiosResponse } from "axios"

const axiosInstane = axios.create({
    headers: {
        "X-Requested-By-Conf": 1,
    },
})

axiosInstane.interceptors.response.use(
    (response) => response,
    function (error) {
        if (
            error?.response?.status === 401 &&
            !window.location.href.includes("SESSION_EXPIRED") && !window.location.href.includes('logout')
        ) {
            localStorage.clear()

            const cookies = document.cookie.split(";")
            if (!cookies.length) return;
            for (let i = 0; i < cookies.length; i++) {
                document.cookie =
                    cookies[i] + "=; expires=" + new Date(0).toUTCString()
            }

            window.location.href =
                "/?reason=SESSION_EXPIRED&redirect_to=" +
                window.location.pathname
            return
        }

        return Promise.reject(error)
    }
)

export class Api {
    apihost: string
    region: string | null

    constructor(apihost: string) {
        this.apihost = apihost
        this.region = null
    }

    public setRegion(region: string | null) {
        this.region = region
        axiosInstane.defaults.headers.common["X-Current-Region"] = region || ""
    }

    public getClient() {
        return axios
    }

    public sendFormData(
        action: string,
        form: FormData
    ): Promise<AxiosResponse<any>> {
        return axiosInstane.post(this.apihost + action, form, {
            withCredentials: true,
            headers: { "Content-Type": "multipart/form-data" },
        })
    }

    public post<Req, Res>(
        action: string,
        params: any = null,
        data?: Req
    ): Promise<AxiosResponse<Res>> {
        if (params != null) {
            for (let key in params) {
                action = action.replace("{" + key + "}", params[key])
            }
        }

        return axiosInstane.post(this.apihost + action, data, {
            withCredentials: true,
        })
    }

    public delete(
        action: string,
        params: any = null
    ): Promise<AxiosResponse<any>> {
        if (params != null) {
            for (let key in params) {
                action = action.replace(
                    "{" + key + "}",
                    params[key] === undefined ? "" : params[key]
                )
            }
        }

        return axiosInstane.delete(this.apihost + action, {
            withCredentials: true,
        })
    }

    public put(
        action: string,
        params: any = null,
        data?: any
    ): Promise<AxiosResponse<any>> {
        if (params != null) {
            for (let key in params) {
                action = action.replace("{" + key + "}", params[key])
            }
        }

        return axiosInstane.put(this.apihost + action, data, {
            withCredentials: true,
        })
    }

    public get<Res>(
        action: string,
        params: {} = {}
    ): Promise<AxiosResponse<Res>> {
        return axiosInstane.get(this.apihost + action, {
            withCredentials: true,
            params,
        })
    }

    public async getTyped<T = any>(
        action: string,
        params: {} = {},
        ignoreHost: boolean = false
    ): Promise<T> {
        const { data } = await axiosInstane.get<T>(
            (ignoreHost ? "" : this.apihost) + action,
            {
                withCredentials: true,
                params,
            }
        )
        return data
    }
}

const api = new Api(import.meta.env.VITE_APP_API_HOST ?? '')
export default api
