import { Collapse, Tooltip } from "antd"
import { getDateString } from "helpers/getDateString"
import withShowMore from "hocs/withShowMore"
import { FC } from "react"
import { GetReferenceNomenclatureResponse } from "types/api"

const NomenclatureDetailPricesTable: FC<{
    data: GetReferenceNomenclatureResponse["prices"][number]["prices_region"]
}> = ({ data: nomenclaturePrices }) => {
    const getPriceString = (
        price: GetReferenceNomenclatureResponse["prices"][number]["prices_region"][number]
    ) => {
        const basePrice = price.price
        const currency = price.currency?.isoCode ?? "[валюта не указана]"
        const chunks = [basePrice, currency]
        if (!price.priceIncludesVAT) {
            chunks.push(`/ ${price.priceWithVat} с НДС`)
        }
        return chunks.join(" ")
    }

    if (!nomenclaturePrices.length) return null

    return (
        <Collapse
            style={{
                borderRadius: 4,
            }}
            items={
                nomenclaturePrices.map((priceRecord, i) => ({
                    key: i,
                    label: (
                        <div className="calculation-log__header">
                            <span>
                                {priceRecord.typeOfPrice?.name ??
                                    "Цена от поставщика"}
                            </span>
                            <span>{getDateString(priceRecord.startAt)}</span>
                            <span>{getPriceString(priceRecord)} </span>
                        </div>
                    ),
                    children: (
                        <div>
                            {priceRecord?.calculationLog?.map((log) => (
                                <div className="calculation-log__row">
                                    <span className="line">
                                        <Tooltip
                                            placement="right"
                                            overlayInnerStyle={{
                                                textAlign: "center",
                                                maxWidth: 200,
                                            }}
                                            title={
                                                <>
                                                    Строка кода, на которой
                                                    произведена операция.
                                                </>
                                            }
                                        >
                                            {log.line}
                                        </Tooltip>
                                    </span>
                                    <span>{log.type.name}</span>
                                    <span className="number">
                                        <Tooltip
                                            placement="right"
                                            overlayInnerStyle={{
                                                textAlign: "center",
                                                maxWidth: 200,
                                            }}
                                            title={
                                                <>
                                                    Число, используемое для
                                                    применения операции.
                                                </>
                                            }
                                        >
                                            {log.number}
                                        </Tooltip>
                                    </span>
                                    <div className="comment">
                                        {log.comment && (
                                            <Tooltip
                                                placement="right"
                                                overlayInnerStyle={{
                                                    textAlign: "center",
                                                }}
                                                title={<>Комментарий.</>}
                                            >
                                                <span
                                                    style={{
                                                        marginBottom: 10,
                                                        display: "inline-block",
                                                    }}
                                                >
                                                    {log.comment}
                                                </span>
                                            </Tooltip>
                                        )}

                                        <div className="calculation-log__entities">
                                            {Object.entries(log.entities).map(
                                                ([id, name]) => {
                                                    return (
                                                        <div className="calculation-log__entity">
                                                            <Tooltip
                                                                placement="right"
                                                                overlayInnerStyle={{
                                                                    textAlign:
                                                                        "center",
                                                                }}
                                                                title={id}
                                                            >
                                                                <span>
                                                                    {
                                                                        name as string
                                                                    }
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ),
                })) ?? []
            }
            defaultActiveKey={[]}
        />
    )
}

export default withShowMore(NomenclatureDetailPricesTable)
