import { useQuery } from "@tanstack/react-query"
import { Col, Modal, Row } from "antd"
import { SelectEntity } from "components/Selects/types"
import { AsyncSelect } from "components/UI"
import React, { useCallback, useMemo, useState } from "react"
import { Uuid } from "types"
import api from "../../../helpers/api"
import AttributeCopy from "./AttributeCopy"
import { AttributesContainersWrapper } from "./AttributesContainersWrapper"
import { AttributeCopyKeys } from "./settings"

export interface AttributeGroupProducerSeriesLink {
    attribute: {
        id: Uuid
        code: Uuid
        name: string
        valuesList: Record<Uuid, string>
    }
    attributeId: Uuid
    datatypeOptions: Record<Uuid, string>
}

export interface AttributeAvailableValues {
    attribute: {
        id: Uuid
        valuesList: Record<Uuid, string>
    }
    configurableAttributeValues: Uuid[]
}

export const AttributeValuesCompatibility: React.FC = () => {
    document.title = "Конфигуратор сочетаний значений"

    const [activeProducer, setActiveProducer] = useState<SelectEntity | null>(
        null
    )
    const [activeGroup, setActiveGroup] = useState<SelectEntity | null>(null)
    const [activeSeries, setActiveSeries] = useState<SelectEntity | null>(null)
    const [activeModel, setActiveModel] = useState<SelectEntity | null>(null)
    const [showCopyModal, setShowCopyModal] = useState<AttributeCopyKeys>()

    const {
        data: availableAttributesValues,
        isLoading: loadingAvailibleAttributeValues,
        isFetching: fetchingAvailableAttributeValues,
    } = useQuery<AttributeAvailableValues[]>({
        queryKey: [
            "configurator-attributes-configurable-by-model",
            activeSeries,
            activeModel,
        ],
        enabled: !!activeSeries && !!activeModel,
        queryFn: () =>
            api.getTyped("model/get-attribute-links", {
                series: activeSeries?.value,
                model: activeModel?.value,
            }),
    })

    const {
        data: attributesList = [],
        isLoading: loadingAttributes,
        isFetching: fetchAttributes,
    } = useQuery<AttributeGroupProducerSeriesLink[]>({
        enabled:
            !!availableAttributesValues && !!activeGroup && !!activeProducer,
        queryKey: [
            "configurator-attributes-list",
            activeProducer?.value,
            activeGroup?.value,
            activeSeries?.value,
            availableAttributesValues,
        ],
        queryFn: () =>
            api
                .getTyped<AttributeGroupProducerSeriesLink[]>(
                    "attribute/list-by-group-and-producer-and-series",
                    {
                        producer: activeProducer?.value,
                        group: activeGroup?.value,
                        series: activeSeries?.value,
                    }
                )
                .then((attr) =>
                    attr
                        .map<
                            AttributeGroupProducerSeriesLink & {
                                availabilityLink?: AttributeAvailableValues
                            }
                        >((attributeLink) => ({
                            ...attributeLink,
                            availabilityLink: availableAttributesValues?.find(
                                (avbl) => {
                                    return (
                                        avbl.attribute.id ===
                                        attributeLink.attribute.id
                                    )
                                }
                            ),
                        }))
                        .filter((v) => v.availabilityLink)
                        .map((attribute) => ({
                            ...attribute,
                            datatypeOptions: Object.entries(
                                attribute.attribute.valuesList
                            ).reduce<{ [id: Uuid]: string }>(
                                (prev, [valueId, valueLabel]) => {
                                    if (
                                        !attribute.availabilityLink?.configurableAttributeValues.some(
                                            (configurableValueId) =>
                                                configurableValueId === valueId
                                        )
                                    ) {
                                        return prev
                                    }

                                    return {
                                        ...prev,
                                        [valueId]: valueLabel,
                                    }
                                },
                                {}
                            ),
                        }))
                ),
    })

    const disableModal = !activeSeries || !activeModel

    const enitityData = useMemo(() => {
        return {
            group: activeGroup,
            model: activeModel,
            producer: activeProducer,
            series: activeSeries,
        }
    }, [activeGroup, activeModel, activeProducer, activeSeries])

    const hideCopyModal = useCallback(() => {
        setShowCopyModal(undefined)
    }, [setShowCopyModal])

    const isLoading = useMemo(() => {
        return [
            loadingAttributes,
            fetchAttributes,
            loadingAvailibleAttributeValues,
            fetchingAvailableAttributeValues,
        ].some(Boolean)
    }, [
        loadingAttributes,
        fetchAttributes,
        loadingAvailibleAttributeValues,
        fetchingAvailableAttributeValues,
    ])

    return (
        <>
            <h1 className="h3 mb-3">Конфигуратор сочетаний значений</h1>
            <div className="control">
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="edit-settings"
                        role="tabpanel"
                        aria-labelledby="edit-settings-tab"
                    >
                        <div className="control-view">
                            <div className="control-view__main">
                                <div className="control-in">
                                    <h5>Основное</h5>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Номенклатурная группа
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                onChange={(
                                                    entity: SelectEntity
                                                ) => {
                                                    setActiveProducer(null)
                                                    setActiveSeries(null)
                                                    setActiveModel(null)
                                                    setActiveGroup(entity)
                                                }}
                                                dataUrl="items-group"
                                                page={1}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Поставщик
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={!activeGroup}
                                                onChange={(
                                                    entity: SelectEntity
                                                ) => {
                                                    setActiveSeries(null)
                                                    setActiveModel(null)
                                                    setActiveProducer(entity)
                                                }}
                                                dataUrl="producer"
                                                params={{
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Серия
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={
                                                    !activeGroup ||
                                                    !activeProducer
                                                }
                                                onChange={(
                                                    entity: SelectEntity
                                                ) => {
                                                    setActiveSeries(entity)
                                                    setActiveModel(null)
                                                }}
                                                dataUrl="series"
                                                params={{
                                                    producer:
                                                        activeProducer?.value,
                                                    itemsGroup:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mb-3">
                                        <label className="col-xl-3 col-form-label">
                                            Модель
                                        </label>
                                        <div className="col-xl-9">
                                            <AsyncSelect
                                                isDisabled={!activeSeries}
                                                onChange={(
                                                    entity: SelectEntity
                                                ) => {
                                                    setActiveModel(entity)
                                                }}
                                                dataUrl="model"
                                                params={{
                                                    series: activeSeries?.value,
                                                    itemsGroups:
                                                        activeGroup?.value,
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <Row gutter={[10, 10]} className="mb-3">
                                        <Col span={24} md={12} xl={8}>
                                            <button
                                                className="btn btn-primary w-100"
                                                disabled={disableModal}
                                                onClick={() =>
                                                    setShowCopyModal(
                                                        AttributeCopyKeys.model
                                                    )
                                                }
                                            >
                                                Скопировать настройки на другую
                                                модель
                                            </button>
                                        </Col>
                                        <Col span={24} md={12} xl={8}>
                                            <button
                                                className="btn btn-primary w-100"
                                                disabled={disableModal}
                                                onClick={() =>
                                                    setShowCopyModal(
                                                        AttributeCopyKeys.modelProducer
                                                    )
                                                }
                                            >
                                                Перенести настройку на
                                                поставщика
                                            </button>
                                        </Col>
                                        <Col span={24} md={12} xl={8}>
                                            <button
                                                className="btn btn-primary w-100"
                                                disabled={disableModal}
                                                onClick={() =>
                                                    setShowCopyModal(
                                                        AttributeCopyKeys.modelSeries
                                                    )
                                                }
                                            >
                                                Перенести настройку на серию
                                            </button>
                                        </Col>
                                    </Row>

                                    {!activeModel &&
                                        "Необходимо заполнить поля выше"}

                                    {activeProducer &&
                                        activeModel &&
                                        activeGroup &&
                                        activeSeries && (
                                            <AttributesContainersWrapper
                                                attributes={attributesList}
                                                groupId={activeGroup.value}
                                                modelId={activeModel.value}
                                                producerId={
                                                    activeProducer.value
                                                }
                                                seriesId={activeSeries.value}
                                                loadingAttributes={isLoading}
                                            />
                                        )}
                                </div>
                            </div>
                            <Modal
                                open={
                                    !!showCopyModal &&
                                    !!activeModel &&
                                    !!activeSeries &&
                                    !!activeGroup &&
                                    !!activeProducer
                                }
                                onCancel={() => setShowCopyModal(undefined)}
                                okButtonProps={{ hidden: true }}
                                cancelButtonProps={{ hidden: true }}
                            >
                                {!!showCopyModal &&
                                    !!activeModel &&
                                    !!activeSeries &&
                                    !!activeGroup &&
                                    !!activeProducer && (
                                        <AttributeCopy
                                            mode={showCopyModal}
                                            entityData={enitityData}
                                            hide={hideCopyModal}
                                        />
                                    )}
                            </Modal>
                            <div className="control-view__info"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
