import { Button, Col, Row } from "antd"
import { Layout } from "components/layouts"
import { Flex } from "components/UI/Flex"
import { UTable } from "components/UTable"
import { useState } from "react"
import { v4 } from "uuid"

const data = Array.from({ length: 2000 })
    .fill(0)
    .map((_, index) => ({
        name: index % 10 ? `Вложенная строка № ${index}` : `Строка № ${index}`,
        id: v4(),
        bool: [true, false][Math.floor(Math.random() * 2)],
        input: ["foo", "bar"][Math.floor(Math.random() * 2)],
        index,
        nested: Boolean(index % 10),
        parentId: Math.floor(index / 10) * 10,
    }))

type Row = (typeof data)[number]

const sorters = {
    desc: (rowA: Row, rowB: Row) => rowB.index - rowA.index,
    asc: (rowA: Row, rowB: Row) => rowA.index - rowB.index,
}

export default function Test() {
    const [selectedRow, setSelectedRow] = useState<Row | null>(null)
    const [chosenRows, setChosenRows] = useState<Row[]>([])

    const [sort, setSort] = useState<keyof typeof sorters>("asc")

    return (
        <>
            <Layout.Detail pageTitle="Тест">
                <Button
                    onClick={() =>
                        setSort((prev) => (prev === "asc" ? "desc" : "asc"))
                    }
                >
                    Сортировка {sort === "asc" ? "↓" : "↑"}
                </Button>
                <Row className="fw">
                    <Col span={12}>
                        <UTable
                            search
                            sort={sorters[sort]}
                            textAlign="center"
                            columns={[
                                {
                                    columnName: () => (
                                        <Flex.Col fullWidth>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={!!chosenRows.length}
                                                onChange={(e) => {
                                                    e.persist()
                                                    e.stopPropagation()
                                                    setChosenRows(() =>
                                                        e.target?.checked
                                                            ? [...data]
                                                            : []
                                                    )
                                                }}
                                            />
                                        </Flex.Col>
                                    ),
                                    render: (row) => {
                                        return (
                                            <Flex.Col fullWidth>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    checked={chosenRows.some(
                                                        (el) => el.id === row.id
                                                    )}
                                                    onChange={(e) => {
                                                        e.persist()
                                                        e.stopPropagation()
                                                        e.nativeEvent.stopPropagation()
                                                        const checked =
                                                            e.target?.checked
                                                        setChosenRows(
                                                            (prev) => {
                                                                return checked
                                                                    ? [
                                                                          ...prev,
                                                                          row,
                                                                      ]
                                                                    : prev.filter(
                                                                          (
                                                                              el
                                                                          ) =>
                                                                              el.id !==
                                                                              row.id
                                                                      )
                                                            }
                                                        )
                                                    }}
                                                />
                                            </Flex.Col>
                                        )
                                    },
                                    width: 10,
                                },
                                {
                                    columnName: "ID",
                                    render: (row) => row.id,
                                    width: 20,
                                    tooltip: true,
                                },
                                {
                                    columnName: "Свойство",
                                    render: (row) => row.name,
                                },
                                {
                                    columnName: "Булево",
                                    render: (row) => row.bool,
                                    width: 10,
                                },
                            ]}
                            isRowActive={(row) => row.id === selectedRow?.id}
                            onRowClick={(row) => {
                                const isNestedRow = row.nested
                                const isSameRow = selectedRow?.id === row.id

                                setSelectedRow(
                                    isNestedRow ? row : isSameRow ? null : row
                                )
                            }}
                            data={data}
                            searchCondition={(row, search) =>
                                row.name
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                            }
                            nested={(row) => row.nested}
                            isHidden={(row) => {
                                return (
                                    row.nested &&
                                    row.parentId !== selectedRow?.parentId
                                )
                            }}
                            content={(row) => {
                                if (row.index % 3 === 0) {
                                    return (
                                        <span>{`Дополнительный контент ${row.index}`}</span>
                                    )
                                }
                                return undefined
                            }}
                        />
                    </Col>
                </Row>
            </Layout.Detail>
        </>
    )
}
